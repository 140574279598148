import api from './private-api.service';

class ApiService {
  getPimpinanDataHome(params = "") {
    return api.get(`/pimpinan_service/main/history${params}`);
  }
  getListStaff(params = "") {
    return api.get(`/pimpinan_service/settings/staf${params}`);
  }
  getRefJabatan(params = "") {
    return api.get(`/pimpinan_service/main/ref/jenis_jabatan${params}`);
  }
  getListNotif(level, params = "") {
    if(level === 1){
      return api.get(`/pimpinan_service/main/notification${params}`);
    }
    if(level === 2){
      return api.get(`/staf_service/main/notification${params}`);
    }
  }
  getDataProfile(params = "") {
    return api.get(`/pimpinan_service/main/profile${params}`);
  }
  getDetailStaff(params = "") {
    return api.get(`/pimpinan_service/settings/staf/detail${params}`);
  }
  getDetailAbsen(params = "") {
    return api.get(`/pimpinan_service/main/history/detail${params}`);
  }
  getDataIzin(params = "") {
    return api.get(`/pimpinan_service/main/history/izin${params}`);
  }
  getDataDLLeft(params = "") {
    return api.get(`/pimpinan_service/main/dinas-luar/staff${params}`);
  }
  getDataDL(params = "") {
    return api.get(`/pimpinan_service/main/dinas-luar${params}`);
  }
  addDataDL(data) {
    return api.post(`/pimpinan_service/main/dinas-luar`, data);
  }
  deleteDataDL(params) {
    return api.delete(`/pimpinan_service/main/dinas-luar/${params}`);
  }
  getDetailIzin(params = "") {
    return api.get(`/pimpinan_service/main/history/detail/izin${params}`);
  }
  getDetailIzinStaff(params = "") {
    return api.get(`/staf_service/main/history/detail/izin${params}`);
  }
  getRekapVersi1(params = "") {
    return api.get(`/pimpinan_service/main/rekap${params}`);
  }
  getDivision(params = "") {
    return api.get(`/pimpinan_service/main/profile/divisi`);
  }
  updateAuthPimpinan(data = {}) {
    return api.put(`/auth_service/admin/settings/update-admin`, data);
  }
  updateProfile(data = {}) {
    return api.put(`/pimpinan_service/main/profile`, data);
  }
  updateStaff(data = {}) {
    return api.put(`/pimpinan_service/settings/staf/update`, data);
  }
  updatePasswordStaff(data = {}) {
    return api.post(`/auth_service/admin/settings/forget-password`, data);
  }
  updateFaceStaff(data = {}) {
    return api.put(`/pimpinan_service/settings/staf/update-face`, data);
  }
  updateIzin(data = {}) {
    return api.put(`/pimpinan_service/main/izin/action`, data);
  }
  putNotifRead(level, data = {}) {
    if(level === 1){
      return api.put(`/pimpinan_service/main/notification/click`, data);
    }
    if(level === 2){
      return api.put(`/staf_service/main/notification/click`, data);
    }
  }
  putDivision(data = {}) {
    return api.put(`/pimpinan_service/main/profile/divisi`, data);
  }
  createStaff(data = {}) {
    return api.post(`/pimpinan_service/settings/staf/create`, data);
  }
  createDivision(data = {}) {
    return api.post(`/pimpinan_service/main/profile/divisi`, data);
  }

  deleteStaff(data = {}) {
    return api.delete(`/pimpinan_service/settings/staf/delete`, {
      data: data,
    });
  }
  deleteDivision(params = '') {
    return api.delete(`/pimpinan_service/main/profile/divisi/${params}`);
  }

  //STAF SERVICE
  getInfoStaf(data = {}) {
    return api.post(`/staf_service/profile/get_info`, data);
  }
  getNewsStaf(params = "") {
    return api.get(`/staf_service/profile/my-news${params}`);
  }
  getHistoryAbsenStaf(params = "") {
    return api.get(`/staf_service/main/history${params}`);    
  }
  getHistoryIzinStaf(params = "") {
    return api.get(`/staf_service/main/history/izin${params}`);
  }
  getRefJenisIzinStaf(params = "") {
    return api.get(`/staf_service/main/ref/jenis-absen${params}`);
  }
  getRekapStafVersi1(params = "") {
    return api.get(`/staf_service/main/rekap${params}`);
  }

  postAbsenStafIn(data = {}) {
    return api.post(`/staf_service/main/presence/in`, data);
  }
  postAbsenStafOut(data = {}) {
    return api.post(`/staf_service/main/presence/out`, data);
  }
  postIzinStaf(data = {}) {
    return api.post(`/staf_service/main/izin`, data);
  }
}

export default new ApiService();
