import axios from "axios";
import Swal from 'sweetalert2'
import TokenService from "./local.data.service";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  headers: {
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["x-access-token"] = token;
      config.headers["x-api-key"] = process.env.REACT_APP_BASE_API_KEY;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth_service/auth" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await instance.post("/auth_service/auth/refreshtoken", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });
          const { accessToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken);
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (err.response.status === 403 && !originalConfig._retry) {
        Swal.fire({
          icon: 'warning',
          title: 'Mohon Maaf',
          text: 'Mohon Maaf Anda Tidak Memiliki Akses!'
        })
      } else if (err.response.status === 500 && !originalConfig._retry) {
        Swal.fire({
          icon: 'error',
          title: 'Mohon Maaf',
          text: 'Sedang Terjadi Error Pada Server!'
        })
      }
    }
    return Promise.reject(err);
  }
);
export default instance;
