import React, { useState, useEffect, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown, faClock } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Col, Container, Row, Modal, ListGroup, ButtonGroup } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker';
import useFCM from '../../../hooks/useFCM';
import apiService from '../../../services/api.service';

const RiwayatIzinView = (props) => {
    const { t } = useTranslation();
    const [_payload] = useFCM();
    const [modalShow, setModalShow] = useState(false);
    const [dataModal, setDataModal] = useState(null);
    const [infoAbsent, setInfoAbsent] = useState(null);
    const [myView, setMyView] = useState(0);
    const [startDate, setStartDate] = useState(new Date());

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button size="lg" variant="primary" onClick={onClick} ref={ref}>
            {moment(value).format('dddd, Do MMMM YYYY')} &nbsp;
            <FontAwesomeIcon icon={faChevronCircleDown} />
        </Button>
    ));

    useEffect(() => {
        async function fetchData() {
            await apiService.getDataIzin(`?status=${myView}&date=${moment(startDate).format('yyyy-MM-DD')}`)
                .then((res) => {
                    setInfoAbsent(res?.data);
                });
        }
        fetchData();
    }, [_payload]);

    const showByView = async (e) => {
        setMyView(e);
        if (e === 99) {
            await apiService.getDataIzin()
                .then((res) => {
                    setInfoAbsent(res?.data);
                });
        } else {
            await apiService.getDataIzin(`?status=${e}&date=${moment(startDate).format('yyyy-MM-DD')}`)
                .then((res) => {
                    setInfoAbsent(res?.data);
                });
        }

    }

    const showByDate = async (date) => {
        setStartDate(date);
        await apiService.getDataIzin(`?status=${myView}&date=${moment(date).format('yyyy-MM-DD')}`)
            .then((res) => {
                setInfoAbsent(res?.data);
            })
            .catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    text: err.response.data.message,
                    icon: 'error'
                })
            });
    }

    const refreshInfo = async () => {
        await apiService.getDataIzin()
            .then(res => {
                setInfoAbsent(res?.data);
            })
            .catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    text: err.response.data.message,
                    icon: 'error'
                })
            });
    };

    const showModalDetail = async (e) => {
        await apiService.getDetailIzin(`?id_izin=${e?.id_izin}`)
            .then(res => {
                setModalShow(true);
                setDataModal(res?.data);
            })
            .catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    text: err.response.data.message,
                    icon: 'error'
                })
            });
    }

    const acceptIzin = async (e, status_diterima = 2) => {
        const data = {
            id_izin: e?.detail?.id_izin,
            status_diterima: status_diterima
        }
        await apiService.updateIzin(data)
            .then(res => {
                Swal.fire({
                    title: 'Berhasil!',
                    text: res.data.message,
                    icon: 'success'
                })
                    .then(() => {
                        setModalShow(false);
                        refreshInfo(startDate);
                    })
            })
            .catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    text: err.response.data.message,
                    icon: 'error'
                })
            });
    }

    return (
        <div className="main-home">
            <br />
            <Container>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => showByDate(date)}
                    customInput={<ExampleCustomInput />}
                />
            </Container>
            <Card className="my-home-card">
                <Card.Body>
                    <Container>
                        <div className='text-center'>
                            Filter : <br />
                            <ButtonGroup aria-label="Basic example">
                                <Button variant="secondary" className={myView === 0 ? 'active' : ''} onClick={() => showByView(0)}>Belum Diperiksa</Button>
                                <Button variant="secondary" className={myView === 3 ? 'active' : ''} onClick={() => showByView(3)}>Ditolak</Button>
                                <Button variant="secondary" className={myView === 1 ? 'active' : ''} onClick={() => showByView(1)}>Diterima</Button>
                                <Button variant="primary" className={myView === 99 ? 'active' : ''} onClick={() => showByView(99)}>Lihat Semua</Button>
                            </ButtonGroup>
                        </div>
                        <Row>
                            <Col sm={12}>
                                {
                                    !_.isEmpty(infoAbsent?.data) ?
                                        infoAbsent?.data?.map((item, index) => {
                                            return (
                                                <Card
                                                    key={index}
                                                    style={{
                                                        borderRadius: 13,
                                                        backgroundColor: item?.status_diterima === 0 ? '#aacfcf' : item?.status_diterima === 1 ? '#aacfcf' : '#f8c4b4',
                                                        margin: '15px 0px 15px 0px',
                                                        cursor: 'pointer'
                                                    }}
                                                    border={false}
                                                    onClick={() => showModalDetail(item)}
                                                >
                                                    <Card.Body className='d-flex flex-row justify-content-between w-100'>
                                                        <div>
                                                            <div>
                                                                <h5>{item?.nama_user}</h5>
                                                            </div>
                                                            <div>
                                                                <h6>{item?.keterangan}</h6>
                                                            </div>
                                                        </div>
                                                        <div style={{ textAlign: 'end' }}>
                                                            <h5>{moment(item?.create_date).format('DD MMMM YYYY')}</h5>
                                                            <h5>{item?.approve}</h5>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            )
                                        })
                                        :
                                        <div className='text-center'>
                                            <br />
                                            <h3>
                                                Data Tidak Ada!
                                            </h3>
                                        </div>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>

            {/* MODAL DETAIL */}
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Detail Izin {dataModal && dataModal?.detail?.nama_user}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="card-riwayat-absen">
                        <Card.Body className="container-img-riwayat">
                            <Row>
                                <Col sm={6}>
                                    <h5>Jenis Izin</h5>
                                    <h6>{dataModal && dataModal?.detail?.jenis_izin}</h6>
                                    <br />
                                    <h5>Dari Tanggal</h5>
                                    <h6>{dataModal && dataModal?.detail?.dari_tanggal}</h6>
                                    <br />
                                    <h5>Sampai Tanggal</h5>
                                    <h6>{dataModal && dataModal?.detail?.sampai_tanggal}</h6>
                                    <br />
                                    <h5>Keterangan</h5>
                                    <h6>{dataModal && dataModal?.detail?.keterangan}</h6>
                                </Col>
                                <Col sm={6} className='d-flex align-items-center justify-content-center'>
                                    {
                                        dataModal && dataModal?.data ?
                                            dataModal?.data?.length > 0 ?
                                                <img src={dataModal?.data[0]?.name_image_in} alt="izin_bukti" className="imageOne" style={{ height: 'auto', minWidth: 300 }} />
                                                :
                                                <>
                                                </>
                                            :
                                            <></>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    {
                        dataModal?.detail?.status_diterima === 1 ?
                            <Button onClick={() => setModalShow(false)}>Close</Button>
                        :
                            <>
                                <Button variant='success' onClick={() => acceptIzin(dataModal, 1)}>Terima</Button>
                                <Button variant='danger' onClick={() => acceptIzin(dataModal, 3)}>Tolak</Button>
                                <Button onClick={() => setModalShow(false)}>Close</Button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RiwayatIzinView;
