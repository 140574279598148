class TokenService {
    getLocalRefreshToken() {
        const user = JSON.parse(sessionStorage.getItem("remember_me"));
        return user?.refreshToken;
    }
    getLocalAccessToken() {
        const user = JSON.parse(sessionStorage.getItem("remember_me"));
        return user?.accessToken;
    }
    updateLocalAccessToken(token) {
        let user = JSON.parse(sessionStorage.getItem("remember_me"));
        user.accessToken = token;
        sessionStorage.setItem("remember_me", JSON.stringify(user));
    }
    getUser() {
        return JSON.parse(sessionStorage.getItem("user"));
    }
    setUser(user) {
        sessionStorage.setItem("user", JSON.stringify(user));
    }
    removeUser() {
        sessionStorage.removeItem("user");
    }
}
export default new TokenService();