import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown, faClock, faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Container, Spinner } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import useLocalStorage from '../../hooks/useLocalStorage';
import Swal from 'sweetalert2'
import MyApi from '../../api/MyApi';
import CardPersonStaff from '../../components/widgets/CardPersonStaff';
import DatePicker from 'react-datepicker';
import { forwardRef } from 'react';
import useFCM from '../../hooks/useFCM';

const KantorView = () => {
    const { t } = useTranslation();
    const initialValue = [
        {
            title: 'Ini Title 1',
            url: 'https://img.freepik.com/free-vector/hand-painted-watercolor-pastel-sky-background_23-2148902771.jpg'
        }
    ];

    const [remember] = useLocalStorage('remember_me', []);
    const [_payload] = useFCM();
    const [preview, setPreview] = useState();
    const [listNews, setListNews] = useState(initialValue);
    const [titleListNews, setTitleListNews] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [infoAbsent, setInfoAbsent] = useState(null);
    const [dt, setDt] = useState(moment().format('h:mm:ss A'));

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button size="lg" variant="primary" onClick={onClick} ref={ref}>
            {moment(value).format('dddd, Do MMMM YYYY')} &nbsp;
            <FontAwesomeIcon icon={faChevronCircleDown} />
        </Button>
    ));

    useEffect(() => {
        const fetchInfo = async () => {
            try {
                const res = await axios({
                    method: 'get',
                    url: process.env.REACT_APP_BASE_API + '/admin/presence/in',
                    headers: {
                        "Authorization": remember.data && "token=" + remember.data.token
                    },
                    data: {
                        "check_in": startDate
                    }
                }
                );
                setInfoAbsent(res.data.data);
            } catch (err) {
                // some error handling
                console.log(err);
            }
        };
        fetchInfo();
    }, [remember.data]);

    useEffect(async () => {
        const isMounted = true;
        await MyApi.get('/admin/presence/in', {
            headers: {
                "Authorization": remember.data && "token=" + remember.data.token
            }
        }).then((res) => {
            if (isMounted) {
                setInfoAbsent(res?.data?.data);
            }
        });
        return () => { isMounted = false };
    }, [_payload]);

    useEffect(() => {
        const config = {
            headers: {
                "Authorization": remember.data && "token=" + remember.data.token
            }
        };
        const fetchNews = () => {
            MyApi.get('/user/profile/my-news', config)
                .then(res => {
                    setListNews(res.data.data);
                })
                .catch(err => {
                    // some error handling
                    // console.log(err)
                });
        };
        fetchNews();
    }, [remember.data]);

    const refreshInfo = (date) => {
        const config = {
            headers: {
                "Authorization": remember.data && "token=" + remember.data.token
            }
        };
        setStartDate(date);

        MyApi.get('/admin/presence/in?check_in=' + moment(date).format('yyyy-MM-DD'), {
            headers: {
                "Authorization": remember.data && "token=" + remember.data.token
            }
        })
            .then(res => {
                setInfoAbsent(res.data.data)
            })
            .catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    text: err.response.data.message,
                    icon: 'error'
                })
            });
    };

    const callBackCard = (x) => {
        refreshInfo(new Date());
    }

    return (
        <div className="main-home">
            <Container>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => refreshInfo(date)}
                    customInput={<ExampleCustomInput />}
                />
                {moment(startDate).format('DDMMyyyy') == moment().format('DDMMyyyy') ? <div className='text-center mt-4 mb-4'><Spinner animation="border" variant="info" /><br />Live update...</div> : <></>}
                <CardPersonStaff data={infoAbsent} callBack={callBackCard} token={remember?.data?.token} level={remember?.data?.level_user}/>
            </Container>
            <div>
                <Card className="my-home-card">
                    <Card.Body>
                        <Container>
                            <div className="my-card-indent">
                                <div>
                                    <div className="child-time-left" style={{ background: '#9fc5e8' }}>
                                        {t('kantor.my_waktu')}
                                    </div>
                                    <div className="child-time-right">
                                        <FontAwesomeIcon icon={faClock} />
                                    </div>
                                </div>
                                <h6>{dt}</h6>
                                <h6>{moment().format('dddd, Do MMMM YYYY')}</h6>
                            </div>

                            <div className="my-card-news">
                                <h5>{t('kantor.my_news')}</h5>
                                <Carousel
                                    infiniteLoop={true}
                                    autoPlay={true}
                                    showThumbs={false}
                                    showStatus={false}
                                    stopOnHover={true}
                                    onChange={(e) => setTitleListNews(e)}
                                >
                                    {listNews.map((i, index) => (
                                        <div className="child-img-news" key={index}>
                                            <img src={i.url_image} alt="curousel news" />
                                            <p>{i.title}</p>
                                        </div>
                                    ))}
                                </Carousel>
                                <p>{listNews[titleListNews].title}</p>
                            </div>
                        </Container>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default KantorView;
