import React, { useState, useEffect, forwardRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown, faImage, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Col, Container, OverlayTrigger, Row, Modal, Form, ButtonGroup, InputGroup, Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker';
import useFCM from '../hooks/useFCM';
import apiService from '../services/api.service';
import PresenceContext from '../context/PresenceContext';
import myHelper from '../helpers/index';
import { useHistory } from 'react-router-dom';

const RiwayatIzinView = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [_payload] = useFCM();
    const { remember } = useContext(PresenceContext);

    const [modalShow, setModalShow] = useState(false);
    const [modalForm, setModalForm] = useState(false);
    const [dataModal, setDataModal] = useState(null);
    const [infoAbsent, setInfoAbsent] = useState(null);
    const [listJenisIzin, setListJenisIzin] = useState([]);
    const [myView, setMyView] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [startDateIzin, setStartDateIzin] = useState(new Date());
    const [endDateIzin, setEndDateIzin] = useState(new Date().setDate(new Date().getDate() + 1));
    const [uploadAvatar, setUploadAvatar] = useState(null);
    const [isFileLimit, setIsFileLimit] = useState(false);

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button size="md" variant="primary" onClick={onClick} ref={ref}>
            {moment(value).format('dddd, Do MMMM YYYY')} &nbsp;
            <FontAwesomeIcon icon={faChevronCircleDown} />
        </Button>
    ));

    const CustomStartIzin = forwardRef(({ value, onClick }, ref) => (
        <Button variant="outline-primary" onClick={onClick} ref={ref}>
            Pilih Tanggal
        </Button>
    ));

    const changeAvatar = (e) => {
        setUploadAvatar(e.target.files[0]);
    }

    useEffect(() => {
        async function fetchData() {
            await apiService.getHistoryIzinStaf(`?status=${0}`)
                .then((res) => {
                    setInfoAbsent(res?.data);
                });
            await apiService.getRefJenisIzinStaf(`?is_pengajuan_izin=true`)
                .then((res) => {
                    setListJenisIzin(res?.data?.data);
                });
        }
        fetchData();
    }, [_payload]);

    const showByView = async (e) => {
        setMyView(e);
        if (e === 99) {
            await apiService.getHistoryIzinStaf()
                .then((res) => {
                    setInfoAbsent(res?.data);
                });
        } else {
            await apiService.getHistoryIzinStaf(`?status=${e}&date=${moment(startDate).format('yyyy-MM-DD')}`)
                .then((res) => {
                    setInfoAbsent(res?.data);
                });
        }

    }

    const showByDate = async (date) => {
        setStartDate(date);
        await apiService.getHistoryIzinStaf(`?status=${myView}&date=${moment(date).format('yyyy-MM-DD')}`)
            .then((res) => {
                setInfoAbsent(res?.data);
            })
            .catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    text: err.response.data.message,
                    icon: 'error'
                })
            });
    }


    const changeIzinDate = async (date, start = true) => {
        if (start) {
            setStartDateIzin(date);
        } else {
            setEndDateIzin(date);
        }
    }

    const showModalDetail = async (e) => {
        await apiService.getDetailIzinStaff(`?id_izin=${e?.id_izin}`)
            .then(res => {
                setModalShow(true);
                setDataModal(res?.data);
            })
            .catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    text: err.response.data.message,
                    icon: 'error'
                })
            });
    }

    const addIzinStaff = async (e) => {
        e.preventDefault();
        if(uploadAvatar === null) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Foto tidak boleh kosong!',
            });
            return;
        }
        try {
            const formData = new FormData();
            formData.append('image', uploadAvatar);
            formData.append('keterangan', e.target.keterangan.value);
            formData.append('tanggal_mulai', new Date(startDateIzin));
            formData.append('id_jenis_absen', e.target.radio_jenis_izin.value);
            formData.append('tanggal_selesai', new Date(endDateIzin));

            await apiService.postIzinStaf(formData)
                .then(res => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Berhasil!',
                        text: 'Izin berhasil diajukan!',
                    })
                    .then(() => {
                        uploadAvatar(null);
                        setModalForm(false);
                        history.replace('/pengajuan_izin');
                    })
                })
                .catch(err => {
                    if(err){
                        if(err.response.status === 400) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Gagal mengajukan Izin!',
                            });
                        }
                        
                        if(err.response.status === 406) {
                            Swal.fire({
                                icon: 'warning',
                                title: 'Oops...',
                                text: err.response.data.message,
                            });
                        }
                        if(err.response.status === 422){
                            if (err?.response?.data?.errors?.length > 0) {
                                const errors = myHelper.getErrorMessages(err.response.data.errors);
                                Swal.fire({
                                  icon: 'error',
                                  title: 'Mohon periksa kembali data yang anda masukkan!',
                                  html: errors.join('<br/>'),
                                });
                            }
                        }
                    }
                });
        } catch (err) {
            // some error handling
            console.log(err);
        }
    };

    const getDateActual = (date) => {
        const dateActual = new Date(date);
        var year = dateActual.getFullYear();
        var month = dateActual.getMonth();
        var day = dateActual.getDate();

        return [year, month, day];
    }

    return (
        <div className="main-home">
            <br />
            <Container>
                <div className='d-flex flex-row'>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => showByDate(date)}
                        customInput={<ExampleCustomInput />}
                    />
                    <Button variant="warning" size='md' style={{ minWidth: 150, height: 'fit-content', padding: 5 }} onClick={() => setModalForm(true)}>
                        <h6 className='w-100'>Ajukan Izin</h6>
                    </Button>
                </div>
            </Container>
            <Card className="my-home-card">
                <Card.Body>
                    <Container>
                        <div className='text-center'>
                            Filter : <br />
                            <ButtonGroup aria-label="Basic example">
                                <Button variant="secondary" className={myView === 0 ? 'active' : ''} onClick={() => showByView(0)}>Belum Diperiksa</Button>
                                <Button variant="secondary" className={myView === 3 ? 'active' : ''} onClick={() => showByView(3)}>Ditolak</Button>
                                <Button variant="secondary" className={myView === 1 ? 'active' : ''} onClick={() => showByView(1)}>Diterima</Button>
                                <Button variant="primary" className={myView === 99 ? 'active' : ''} onClick={() => showByView(99)}>Lihat Semua</Button>
                            </ButtonGroup>
                        </div>
                        <Row>
                            <Col sm={12}>
                                {
                                    !_.isEmpty(infoAbsent?.data) ?
                                        infoAbsent?.data?.map((item, index) => {
                                            return (
                                                <Card
                                                    key={index}
                                                    style={{
                                                        borderRadius: 13,
                                                        backgroundColor: item?.status_diterima === 0 ? '#aacfcf' : item?.status_diterima === 1 ? '#aacfcf' : '#f8c4b4',
                                                        margin: '15px 0px 15px 0px',
                                                        cursor: 'pointer'
                                                    }}
                                                    border={false}
                                                    onClick={() => showModalDetail(item)}
                                                >
                                                    <Card.Body className='d-flex flex-row justify-content-between w-100'>
                                                        <div>
                                                            <div>
                                                                <h5>{item?.nama_user}</h5>
                                                            </div>
                                                            <div>
                                                                <h6>{item?.keterangan}</h6>
                                                            </div>
                                                        </div>
                                                        <div style={{ textAlign: 'end' }}>
                                                            <h5>{moment(item?.tanggal_masuk).format('DD MMMM YYYY')}</h5>
                                                            <h5>{item?.approve}</h5>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            )
                                        })
                                        :
                                        <div className='text-center'>
                                            <br />
                                            <h3>
                                                Data Tidak Ada!
                                            </h3>
                                        </div>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>

            {/* MODAL DETAIL */}
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Detail Izin {dataModal && dataModal?.detail?.nama_user}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="card-riwayat-absen">
                        <Card.Body className="container-img-riwayat">
                            <Row>
                                <Col sm={6}>
                                    <h5>Jenis Izin</h5>
                                    <h6>{dataModal && dataModal?.detail?.jenis_izin}</h6>
                                    <br />
                                    <h5>Dari Tanggal</h5>
                                    <h6>{dataModal && dataModal?.detail?.dari_tanggal}</h6>
                                    <br />
                                    <h5>Sampai Tanggal</h5>
                                    <h6>{dataModal && dataModal?.detail?.sampai_tanggal}</h6>
                                    <br />
                                    <h5>Keterangan</h5>
                                    <h6>{dataModal && dataModal?.detail?.keterangan}</h6>
                                </Col>
                                <Col sm={6} className='d-flex align-items-center justify-content-center'>
                                    {
                                        dataModal && dataModal?.data ?
                                            dataModal?.data?.length > 0 ?
                                                <img src={dataModal?.data[0]?.name_image_in} alt="izin_bukti" className="imageOne" style={{ height: 'auto', minWidth: 300 }} />
                                                :
                                                <>
                                                </>
                                            :
                                            <></>
                                    }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>Tutup</Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL FORM */}
            <Modal
                show={modalForm}
                onHide={() => setModalForm(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Form onSubmit={addIzinStaff}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Isi Form Izin Anda
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input type="hidden" name="id_user" value={_payload?.id_user} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama</Form.Label>
                            <Form.Control type="text" placeholder={remember?.data?.nama} disabled />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Jenis Izin</Form.Label>
                            {
                                listJenisIzin?.length > 0 && listJenisIzin?.map((item, index) => {
                                    return (
                                        <Form.Check
                                            key={index}
                                            type={'radio'}
                                            label={item?.nama}
                                            name={`radio_jenis_izin`}
                                            id={`radio-jenis-izin`}
                                            value={item?.id_jenis_absen}
                                        />
                                    )
                                })
                            }
                        </Form.Group>
                        <Row>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                    <Form.Label>Mulai Tanggal</Form.Label>
                                    <InputGroup className="mb-3">
                                        <DatePicker
                                            selected={startDateIzin}
                                            minDate={new Date()}
                                            onChange={(date) => changeIzinDate(date, true)}
                                            customInput={<CustomStartIzin />}
                                        />
                                        <Form.Control
                                            type='text'
                                            disabled
                                            value={moment(startDateIzin).format('dddd, Do MMMM YYYY')}
                                            placeholder="Recipient's username"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                    <Form.Label>Sampai Tanggal</Form.Label>
                                    <InputGroup className="mb-3">
                                        <DatePicker
                                            selected={endDateIzin}
                                            minDate={new Date()}
                                            onChange={(date) => changeIzinDate(date, false)}
                                            customInput={<CustomStartIzin />}
                                        />
                                        <Form.Control
                                            type='text'
                                            disabled
                                            value={moment(endDateIzin).format('dddd, Do MMMM YYYY')}
                                            placeholder="Recipient's username"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <div className='w-100 d-flex justify-content-end'>
                                    <h6>Selama: {moment(getDateActual(endDateIzin)).diff(getDateActual(startDateIzin), 'days') + 1} Hari</h6>
                                </div>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Keterangan</Form.Label>
                            <Form.Control as="textarea" rows={3} name='keterangan' />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput10">
                            <Form.Label>Upload Foto Surat Keterangan atau Bukti Sakit</Form.Label><br />
                            {
                                uploadAvatar === null && (
                                    <label
                                        style={{
                                            border: '1px solid #000',
                                            color: '#0daaaa',
                                            borderRadius: 5,
                                            padding: '10px 15px 10px 15px',
                                            cursor: 'pointer',
                                            display: 'inline-block',
                                            fontSize: 24,
                                            ':hover': {
                                                backgroundColor: '#0daaaa',
                                                color: '#fff'
                                            }
                                        }}
                                    ><FontAwesomeIcon icon={faImage} />
                                        <input id='imageUpload' hidden accept="image/png, image/jpeg, image/jpg"
                                            multiple type="file" disabled={isFileLimit} onChange={changeAvatar} />
                                    </label>
                                )
                            }
                        </Form.Group>
                        <div>
                            {
                                uploadAvatar && (
                                    <span>
                                        <img
                                            src={URL.createObjectURL(uploadAvatar)}
                                            width={200}
                                            height={200}
                                            style={{ borderRadius: 5, marginRight: 10 }}
                                            alt={`avatar`}
                                        />
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip-right">
                                                    Hapus Foto
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                style={{ color: 'red', cursor: 'pointer' }}
                                                onClick={() => setUploadAvatar(null)}
                                            />
                                        </OverlayTrigger>
                                    </span>
                                )
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='success' type='submit'>Ajukan</Button>
                        <Button variant='danger' onClick={() => setModalForm(false)}>Tutup</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
};

export default RiwayatIzinView;
