import React, { useLayoutEffect, useRef } from "react";
// import "./styles.css";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default function ChartLine(props) {
    const series1Ref = useRef(null);
    const series2Ref = useRef(null);
    const series3Ref = useRef(null);
    const legend1Ref = useRef(null);
    const xAxisRef = useRef(null);

    // This code will only run one time
    useLayoutEffect(() => {
        let root = am5.Root.new(props.id);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panY: false,
                layout: root.verticalLayout
            })
        );

        // Create Y-axis
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                extraTooltipPrecision: 1,
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

        // Create X-Axis
        let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {}),
                categoryField: props.name_x
            })
        );

        // Create series
        let series1 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "Telat",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "jumlah_hadir_telat",
                categoryXField: props.name_x,
                tooltip: am5.Tooltip.new(root, {})
            })
        );
        series1.get("tooltip").label.set("text", "[bold]Telat[/]\n{valueX}: {valueY}");

        let series2 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "Tepat Waktu",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "jumlah_hadir_ontime",
                categoryXField: props.name_x,
                tooltip: am5.Tooltip.new(root, {})
            })
        );
        series2.get("tooltip").label.set("text", "[bold]Tepat Waktu[/]\n{valueX}: {valueY}");

        let series3 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "Belum Hadir",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "jumlah_tidak_hadir",
                categoryXField: props.name_x,
                tooltip: am5.Tooltip.new(root, {})
            })
        );
        series3.get("tooltip").label.set("text", "[bold]Belum Hadir[/]\n{valueX}: {valueY}");

        // Add legend
        let legend = chart.children.push(am5.Legend.new(root, {}));
        legend.data.setAll(chart.series.values);

        // Add cursor
        chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomXY",
            xAxis: xAxis
        }));

        xAxisRef.current = xAxis;
        series1Ref.current = series1;
        series2Ref.current = series2;
        series3Ref.current = series3;
        series3Ref.current = series3;

        return () => {
            root.dispose();
        };
    }, []);

    // This code will only run when props.data changes
    useLayoutEffect(() => {
        xAxisRef.current.data.setAll(props.data);
        series1Ref.current.data.setAll(props.data);
        series2Ref.current.data.setAll(props.data);
        series3Ref.current.data.setAll(props.data);
    }, [props.data]);

    return <div id={props.id} style={{ width: "100%", height: "500px" }}></div>;
}
