import React, { useState, useEffect, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faChevronCircleDown, faClock, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Button, Card, Col, Container, Row, Modal, ListGroup, Alert } from 'react-bootstrap';
import _, { set } from 'lodash';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker';
import useFCM from '../../../hooks/useFCM';
import apiService from '../../../services/api.service';
import marker from '../../../img/marker_map_icon.svg';

const iconPerson = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    iconAnchor: [32, 32],
    popupAnchor: [-15, -32],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [32, 32],
    className: ''
});

const RiwayatPresensiView = (props) => {
    const { t } = useTranslation();
    const [_payload] = useFCM();
    const [modalShow, setModalShow] = useState(false);
    const [dataModal, setDataModal] = useState(null);
    const [infoAbsent, setInfoAbsent] = useState(null);
    const [dt, setDt] = useState(moment().format('h:mm:ss A'));
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [latOut, setLatOut] = useState(null);
    const [lngOut, setLngOut] = useState(null);
    const [latFake, setLatFake] = useState(null);
    const [lngFake, setLngFake] = useState(null);
    const [status, setStatus] = useState(null);
    const [startDate, setStartDate] = useState(new Date());

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button size="lg" variant="primary" onClick={onClick} ref={ref}>
            {moment(value).format('dddd, Do MMMM YYYY')} &nbsp;
            <FontAwesomeIcon icon={faChevronCircleDown} />
        </Button>
    ));

    useEffect(() => {
        async function fetchData() {
            await apiService.getPimpinanDataHome(`?is_izin=0`)
                .then((res) => {
                    setInfoAbsent(res?.data);
                });
        }
        fetchData();
    }, [_payload]);

    const refreshInfo = async (date) => {
        setStartDate(date);
        await apiService.getPimpinanDataHome(`?date=${moment(date).format('yyyy-MM-DD')}&is_izin=0`)
            .then(res => {
                setInfoAbsent(res.data)
            })
            .catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    text: err.response.data.message,
                    icon: 'error'
                })
            });
    };

    const showModalDetail = async (e) => {
        await apiService.getDetailAbsen(`?id_absent=${e?.id_absent}`)
            .then(res => {
                setModalShow(true);
                setDataModal(res?.data?.data);
                setLat(res?.data?.data?.lokasi_masuk?.x);
                setLng(res?.data?.data?.lokasi_masuk?.y);
                setLatOut(res?.data?.data?.lokasi_keluar?.x);
                setLngOut(res?.data?.data?.lokasi_keluar?.y);
                setLatFake(res?.data?.data?.real_location?.x);
                setLngFake(res?.data?.data?.real_location?.y);
                setStatus(res?.data?.data?.id_absent);
            })
            .catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    text: err.response.data.message,
                    icon: 'error'
                })
            });
    }

    return (
        <div className="main-home">
            <br />
            <Container>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => refreshInfo(date)}
                    customInput={<ExampleCustomInput />}
                />
            </Container>
            <Card className="my-home-card">
                <Card.Body>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                {
                                    !_.isEmpty(infoAbsent?.data) ?
                                        infoAbsent?.data?.map((item, index) => {
                                            return (
                                                <Card
                                                    key={index}
                                                    style={{
                                                        borderRadius: 13,
                                                        backgroundColor: item?.is_izin ? '#f8c4b4' : '#aacfcf',
                                                        margin: '15px 0px 15px 0px',
                                                        cursor: 'pointer'
                                                    }}
                                                    border={false}
                                                    onClick={() => showModalDetail(item)}
                                                >
                                                    <Card.Body className='d-flex flex-row justify-content-between w-100'>
                                                        <div>
                                                            <div>
                                                                <h5>{item?.nama_user}</h5>
                                                            </div>
                                                            <div>
                                                                <h6>{item?.is_izin ? item?.status_izin : item?.check_in + ' WIB'}</h6>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h5>{item?.['jenis_absen.nama']}</h5>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            )
                                        })
                                        :
                                        <div className='text-center'>
                                            <h3>
                                                Data Tidak Ada!
                                            </h3>
                                        </div>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>

            {/* MODAL DETAIL */}
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {dataModal && dataModal['jenis_absen.nama']}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="card-riwayat-absen">
                        <Card.Body className="container-card-riwayat">
                            <Card.Title>{dataModal && dataModal?.nama_user}</Card.Title>
                            <Card.Title>{moment(dataModal && dataModal?.tanggal_masuk).format('dddd, Do MMMM YYYY')}</Card.Title>
                            <hr />
                            {
                                dataModal && dataModal?.is_fake_gps ?
                                    <Alert variant="danger">
                                        <i>Terindikasi Menggunakan <b>Fake GPS</b></i> <FontAwesomeIcon icon={faExclamationCircle} />
                                    </Alert>
                                    :
                                    <Alert variant="success">
                                        <i>Tidak Terindikasi Menggunakan <b>Fake GPS</b></i> <FontAwesomeIcon icon={faCheckCircle} />
                                    </Alert>
                            }
                            <div className='d-flex flex-row justify-content-between' style={{ gap: 10 }}>
                                <div><h4>Foto Masuk</h4></div>
                                <div><h4>Foto Pulang</h4></div>
                            </div>
                            <div className='d-flex flex-row' style={{ gap: 10 }}>
                                <img src={dataModal && dataModal.name_image_in} style={{ minHeight: 200 }} alt="img in" className="imageOne" />
                                {dataModal && dataModal.name_image_out != null ? <img src={dataModal && dataModal.name_image_out} alt="img out" className="imageOne" /> : <>-</>}
                            </div>
                        </Card.Body>
                        <Card.Body className="tes-text-riwayat" style={{
                            textTransform: 'capitalize',
                        }}>
                            <ListGroup>
                                <ListGroup.Item>
                                    <div className='d-flex flex-row align-items-center justify-content-between '>
                                        <div>
                                            {t('btn_absen_masuk')} :<br />{moment(dataModal && dataModal?.tanggal_masuk).tz("Asia/Jakarta").format('h:mm:ss A')}
                                        </div>

                                        {!_.isNull(status) ? lat && lng &&
                                            <MapContainer
                                                center={[lat, lng]}
                                                zoom={16}
                                                scrollWheelZoom={false}
                                                dragging={false}
                                                touchZoom={false}
                                                keyboard={false}
                                                zoomControl={false}
                                            >
                                                <TileLayer
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <Marker icon={iconPerson} position={[lat ? lat : 51.505, lng ? lng : -0.09]}>
                                                    <Popup>
                                                        {t('popup_marker')}
                                                    </Popup>
                                                </Marker>
                                            </MapContainer>
                                            :
                                            <>
                                                {status}
                                            </>
                                        }
                                        {_.isNull(status) ?
                                            <div className="map-container">
                                                <Skeleton height={300} baseColor="#f3f3f3" highlightColor="#cccccc" borderRadius={10} />
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div className='d-flex flex-row align-items-center justify-content-between '>
                                        <div>
                                            {t('btn_absen_keluar')} :<br />{dataModal && dataModal?.tanggal_keluar ? moment(dataModal?.tanggal_keluar).tz("Asia/Jakarta").format('h:mm:ss A') : '-'}
                                        </div>
                                        {!_.isNull(status) ? latOut && lngOut &&
                                            <MapContainer
                                                center={[latOut, lngOut]}
                                                zoom={16}
                                                scrollWheelZoom={false}
                                                dragging={false}
                                                touchZoom={false}
                                                keyboard={false}
                                                zoomControl={false}
                                            >
                                                <TileLayer
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <Marker icon={iconPerson} position={[latOut ? latOut : 51.505, lngOut ? lngOut : -0.09]}>
                                                    <Popup>
                                                        {t('popup_marker')}
                                                    </Popup>
                                                </Marker>
                                            </MapContainer>
                                            :
                                            <>
                                                {status}
                                            </>
                                        }
                                        {_.isNull(status) ?
                                            <div className="map-container">
                                                <Skeleton height={300} baseColor="#f3f3f3" highlightColor="#cccccc" borderRadius={10} />
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                            {
                                dataModal && dataModal?.is_fake_gps &&
                                <ListGroup className='mt-4'>
                                    <ListGroup.Item>
                                        <div className='d-flex flex-column align-items-center justify-content-between '>
                                            <div className='mb-2' style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                                                Lokasi Presensi Sebenarnya :
                                            </div>
                                            {!_.isNull(status) ? dataModal && dataModal?.is_fake_gps && latFake && lngFake &&
                                                <MapContainer
                                                    center={[latFake, lngFake]}
                                                    zoom={16}
                                                    scrollWheelZoom={false}
                                                    dragging={false}
                                                    touchZoom={false}
                                                    keyboard={false}
                                                    zoomControl={false}
                                                >
                                                    <TileLayer
                                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    />
                                                    <Marker icon={iconPerson} position={[latFake ? latFake : 51.505, lngFake ? lngFake : -0.09]}>
                                                        <Popup>
                                                            {t('popup_marker')}
                                                        </Popup>
                                                    </Marker>
                                                </MapContainer>
                                                :
                                                <>
                                                    {status}
                                                </>
                                            }
                                            {_.isNull(status) ?
                                                <div className="map-container">
                                                    <Skeleton height={300} baseColor="#f3f3f3" highlightColor="#cccccc" borderRadius={10} />
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            }
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RiwayatPresensiView;
