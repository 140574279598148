import React, { useRef } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';

const MonthlyTable = ({ dateSelected = new Date(), attendanceData = [], showDownload = true }) => {
    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Kehadiran_Table_' + dateSelected.toISOString().slice(0, 10),
        sheet: 'staff_' + dateSelected.toISOString().slice(0, 10)
    })

    const myYear = dateSelected.getFullYear();
    const myMonth = dateSelected.getMonth();
    const numDays = new Date(myYear, myMonth + 1, 0).getDate();

    const setBackground = (day) => {
        let color = 'white';
        switch (day) {
            case 1:
                color = 'green';
                break;
            case 0:
                color = 'red';
                break;
            case 6:
                color = 'red';
                break;
            default:
                color = 'yellow';
                break;
        };
        return color;
    }

    const renderTableHeader = () => {
        const tableHeader = [<th key="__1no">No.</th>, <th key="name" style={{ minWidth: 200, whiteSpace: 'nowrap' }}>Nama</th>];
        const currentDate = new Date(dateSelected);

        for (let i = 0; i < numDays; i++) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() + i);
            const formattedDate = moment(date).tz('Asia/Jakarta').format('DD') //date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
            tableHeader.push(<th key={i + '__renderTgl'}>{formattedDate}</th>);
        }

        return <tr key={'__renderTglHead'}>{tableHeader}</tr>;
    };

    const renderTableData = () => {
        return attendanceData.map((employee, index) => {
            const { name, attendance } = employee;
            const tableData = [<td key="__no">{index + 1}</td>, <td key="__name">{name}</td>];

            for (let i = 0; i < numDays; i++) {
                const attendanceStatus = attendance[i];
                tableData.push(<td key={i + '__renderSolid'} style={{ backgroundColor: setBackground(attendanceStatus), border: '1px solid #000'}}>&nbsp;</td>);
            }

            return <tr key={index + '__renderList'}>{tableData}</tr>;
        });
    };

    return (
        <div>
            <div className='d-flex justify-content-between w-100 mb-4'>
                <div></div>
                <h4 className='text-center'>Rekap {moment(dateSelected).format('MMMM YYYY')}</h4>
                { showDownload && <button onClick={onDownload} className='btn btn-info text-white'> Export Excel </button> }
            </div>
            <Table responsive striped bordered hover ref={tableRef}>
                <thead>{renderTableHeader()}</thead>
                <tbody>{renderTableData()}</tbody>
            </Table>
        </div>
    );
};


export default MonthlyTable;