import _ from "lodash";
import moment from "moment-timezone";
import { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Swal from "sweetalert2";
import MyApi from "../../api/MyApi";
import userDefault from '../../img/user-default.png'

const CardPersonStaff = ({ data, token, callBack, level }) => {
    const [show, setShow] = useState(false);
    const [dataTemp, setDataTemp] = useState({});

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true);
        setDataTemp(e);
    }

    const updateInfo = (e) => {
        e.preventDefault();
        setShow(false);

        MyApi.put('/admin/presence/in', {
            id_absent: dataTemp?.id_absent,
            check_in: moment(e.target.mydate.value + ' ' + e.target.mytime.value).tz('Asia/Jakarta').format('yyyy-MM-DD HH:mm:ss'),
            name: dataTemp?.name,
        }, {
            headers: {
                "Authorization": token && "token=" + token
            },
        })
        .then(res => {
            Swal.fire({
                icon: 'success',
                title: 'Berhasil',
                text: res.data.message,
            }).then(() => {
                callBack(dataTemp);
            })
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Gagal',
                text: err.response.data.message,
            })
        })
    }

    return (
        <>
            {
                _.isEmpty(data) ? <div className="text-center">Tidak ada data</div> :
                    <Row>
                        {
                            data.map((x, index) => {
                                return (
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
                                        <Card className="card-riwayat-absen mt-3">
                                            <Card.Body className="container-img-riwayat">
                                                <img src={x?.name_image_in ? process.env.REACT_APP_BASE_IMAGE_IN_URL + x?.name_image_in : userDefault} alt="img in" style={{
                                                    width: '200px',
                                                    borderRadius: 10
                                                }} />
                                                &nbsp;&nbsp;&nbsp;
                                                {x?.name_image_out && <img src={x?.name_image_out ? process.env.REACT_APP_BASE_IMAGE_OUT_URL + x?.name_image_out : userDefault} alt="img out" style={{
                                                    width: '200px',
                                                    borderRadius: 10
                                                }} />}
                                            </Card.Body>
                                            <Card.Body className="tes-text-riwayat">
                                                <Card.Title>{x?.name ?? 'Anonymous'}</Card.Title>
                                                <Card.Title>{x?.jabatan ?? 'RnD'} | {x?.status_presence ?? '-'}</Card.Title>
                                                <Card.Text>{moment(x?.check_in).format('dddd, Do MMMM YYYY')}<br />{moment(x?.check_in).format('hh:mm:ss A')}</Card.Text>
                                                {level && level === 2 ? <Button variant="primary" onClick={() => handleShow(x)}>Edit Tanggal</Button> : <></>}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
            }
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title>{dataTemp?.name}</Modal.Title>
                </Modal.Header>
                <form onSubmit={updateInfo}>
                    <Modal.Body>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Tanggal</label>
                            <input type="date" name="mydate" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" defaultValue={moment(dataTemp?.check_in).format('yyyy-MM-DD')} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Jam</label>
                            <input type="time" step="1" name="mytime" className="form-control" id="exampleInputPassword1" defaultValue={moment(dataTemp?.check_in).format('hh:mm:ss')} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
};
export default CardPersonStaff;