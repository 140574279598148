import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faChevronCircleDown, faClock, faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Container, Dropdown, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { DateRangePicker } from "react-date-range";
import { useTranslation } from 'react-i18next';
import { addDays, parseISO } from "date-fns";
import { id as localeID } from "date-fns/esm/locale";
import moment from 'moment';
import useLocalStorage from '../../../hooks/useLocalStorage';
import apiService from '../../../services/api.service';
import myHelper from '../../../helpers';
import { forwardRef } from 'react';
import useFCM from '../../../hooks/useFCM';
import ChartLine from '../../../components/chart/ChartLine';
import MonthlyTable from '../../../components/widgets/MonthlyTable';

const RekapView = () => {
    const { t } = useTranslation();

    const myYear = new Date().getFullYear();
    const myMonth = new Date().getMonth();
    const [remember] = useLocalStorage('remember_me', []);
    const [_payload] = useFCM();
    const [dateRange, setDateRange] = useState([`${myYear}0101`, null]);
    const [listChart1, setListChart1] = useState([]);
    const [listChart2, setListChart2] = useState([]);
    const [listTable3, setListTable3] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [startDate2, endDate2] = dateRange;
    const [startDate3, setStartDate3] = useState(new Date(myYear, myMonth, 1));
    const [state, setState] = useState([
        {
            startDate: parseISO(`${myYear}0101`),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const [filterDate, setFilterDate] = useState({
        startDate: moment(`${myYear}0101`).format("DD/MM/YYYY"),
        endDate: moment(new Date()).format("DD/MM/YYYY"),
        awalDate: moment(`${myYear}0101`).format("YYYY-MM-DD"),
        akhirDate: moment(new Date()).format("YYYY-MM-DD"),
    });

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button size="md" variant="primary" onClick={onClick} ref={ref} className='mb-4'>
            {moment(value).format('dddd, Do MMMM YYYY')} &nbsp;
            <FontAwesomeIcon icon={faChevronCircleDown} />
        </Button>
    ));

    const ExampleCustomInput3 = forwardRef(({ value, onClick }, ref) => (
        <Button size="md" variant="primary" onClick={onClick} ref={ref} className='mb-4'>
            {moment(value).format('MMMM YYYY')} &nbsp;
            <FontAwesomeIcon icon={faChevronCircleDown} />
        </Button>
    ));

    useEffect(async () => {
        const isMounted = true;
        await apiService.getRekapVersi1('/?date=' + moment(startDate).format('yyyy-MM-DD'))
            .then((res) => {
                if (isMounted) {
                    setListChart1(res?.data?.data);
                }
            });
        return () => { isMounted = false };
    }, [_payload]);

    useEffect(async () => {
        const isMounted = true;
        await apiService.getRekapVersi1('/between?date1=' + moment(dateRange[0] ?? new Date()).format('yyyy-MM-DD') + '&date2=' + moment(dateRange[1] ?? new Date()).format('yyyy-MM-DD'))
            .then((res) => {
                if (isMounted) {
                    setListChart2(res?.data?.data);
                }
            });
        return () => { isMounted = false };
    }, [dateRange]);

    useEffect(() => {
        const fetchData = async () => {
            const currentDate = new Date(); // Mendapatkan tanggal saat ini
            const firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            await apiService.getRekapVersi1('/per-bulan?date=' + moment(firstDateOfMonth).format('yyyy-MM-DD'))
                .then((res) => {
                    const dataFormated = myHelper.formatedPerMonth(firstDateOfMonth, res?.data?.data);
                    setListTable3(dataFormated);
                });
        }
        fetchData();
    }, [])

    const refreshInfo = async (date) => {
        setStartDate(date);
        await apiService.getRekapVersi1('/?date=' + moment(date).format('yyyy-MM-DD'))
            .then((res) => {
                setListChart1(res?.data?.data);
            });
    };

    const refreshInfo3 = async (date) => {
        setStartDate3(date);
        await apiService.getRekapVersi1('/per-bulan?date=' + moment(date).format('yyyy-MM-DD'))
            .then((res) => {
                const dataFormated = myHelper.formatedPerMonth(date, res?.data?.data);
                setListTable3(dataFormated);
            });
    };

    const myDateRange = async (data) => {
        setState([data.selection]);
        setFilterDate({
            startDate: moment(data.selection.startDate).format("DD/MM/YYYY"),
            endDate: moment(data.selection.endDate).format("DD/MM/YYYY"),
            awalDate: moment(data.selection.startDate).format("YYYY-MM-DD"),
            akhirDate: moment(data.selection.endDate).format("YYYY-MM-DD"),
        });
        await apiService.getRekapVersi1('/between?date1=' + moment(data.selection.startDate ?? new Date()).format('yyyy-MM-DD') + '&date2=' + moment(data.selection.endDate ?? new Date()).format('yyyy-MM-DD'))
            .then((res) => {
                setListChart1(res?.data?.data);
            });
    };

    return (
        <div className="main-home">
            <Container>
                <Card className='mt-4'>
                    <Card.Header>
                        <h4>Rekap Per-hari Presensi Kehadiran</h4>
                    </Card.Header>
                    <Card.Body>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => refreshInfo(date)}
                            customInput={<ExampleCustomInput />}
                        />
                        {
                            listChart1 && listChart1.length > 0 ?
                                <ChartLine data={listChart1} id="chart1" name_x="nama_jabatan" />
                                :
                                <><h4>Data Kosong!</h4></>
                        }
                    </Card.Body>
                </Card>
                <br />
                <Card>
                    <Card.Header>
                        <h4>Rekap Periode Presensi Kehadiran</h4>
                    </Card.Header>
                    <Card.Body>
                        <Dropdown drop="down" align="left" id="dropdown-menu-align-end">
                            <Dropdown.Toggle className="my-dropdown" id="dropdown-basic">
                                <span>
                                    <FontAwesomeIcon icon={faCalendar} />
                                </span>
                                &nbsp; {filterDate.startDate} - {filterDate.endDate} &nbsp;
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ marginTop: 5 }}>
                                <DateRangePicker
                                    onChange={(item) => myDateRange(item)}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    direction="horizontal"
                                    locale={localeID}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                        <br />
                        {
                            listChart2 && listChart2.length > 0 ?
                                <ChartLine data={listChart2} id="chart2" name_x="nama_user" />
                                :
                                <><h4>Data Kosong!</h4></>
                        }
                    </Card.Body>
                </Card>
                <br />
                <Card>
                    <Card.Header>
                        <h4>Rekap Per-bulan Presensi Kehadiran</h4>
                    </Card.Header>
                    <Card.Body>
                        <DatePicker
                            selected={startDate3}
                            onChange={(date) => refreshInfo3(date)}
                            customInput={<ExampleCustomInput3 />}
                            showMonthYearPicker
                        />
                        <MonthlyTable dateSelected={startDate3} attendanceData={listTable3} />
                    </Card.Body>
                </Card>
                <br />
            </Container>
        </div>
    );
};

export default RekapView;
