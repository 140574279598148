import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faImage, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Col, Container, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Swal from 'sweetalert2'
import apiService from '../../../services/api.service';
import myHelper from '../../../helpers/index';
import { useHistory } from 'react-router-dom';

const EditStaffView = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [listJabatan, setListJabatan] = useState([]);
    const [uploadAvatar, setUploadAvatar] = useState(null);
    const [imageLimit, setImageLimit] = useState(1);
    const [isFileLimit, setIsFileLimit] = useState(false);
    const [myJabatan, setJabatan] = useState(null);
    const [detailStaf, setDetailStaf] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await apiService.getRefJabatan()
                    .then(res => {
                        setListJabatan(res.data.data);
                    });
                await apiService.getDetailStaff('/' + props.match.params.id)
                    .then(res => {
                        setDetailStaf(res.data.data);
                    });
            } catch (err) {
                console.log(err);
            }
        };

        fetchData();
    }, []);

    const changeAvatar = (e) => {
        setUploadAvatar(e.target.files[0]);
    }

    const submitUpdateStaff = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (e.target.password.value !== "") {
            if (e.target.password.value !== e.target.confirm_password.value) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Password baru tidak sama!',
                });
                setIsLoading(false);
                return;
            } else {
                await apiService.updatePasswordStaff({
                    id_user: detailStaf?.id_user,
                    password: e.target.password.value,
                    email: detailStaf?.master_accounts[0]?.email,
                })
            }
        }

        if (uploadAvatar !== null) {
            const stateChangingFace = changeFace(detailStaf);
            if (!stateChangingFace) {
                setIsLoading(false);
                return;
            }

        }

        try {
            const dataStaff = {
                id_user: detailStaf?.id_user,
                nama_user: e.target.nama_staf.value,
                id_jabatan: e.target.id_jabatan.value,
                no_telepon: e.target.no_telepon.value,
                tanggal_lahir: e.target.tanggal_lahir.value,
                tempat_lahir: e.target.tempat_lahir.value,
                email: e.target.email.value,
                old_email: detailStaf?.master_accounts[0]?.email,
                gelar: e.target.gelar.value,
            }

            await apiService.updateStaff(dataStaff)
                .then(res => {
                    if (res.data.status === 200) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Berhasil',
                            text: 'Data berhasil diubah!',
                        });
                        history.replace('/akun-manajemen/staff-manajemen');
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Data gagal diubah!',
                        });
                        setIsLoading(false);
                    }
                })
                .catch(err => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Data gagal diubah!',
                    });
                    setIsLoading(false);
                })
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    const changeFace = async (e) => {
        try {
            const formData = new FormData();
            formData.append('image', uploadAvatar);
            formData.append('id_user', detailStaf?.id_user);

            await apiService.updateFaceStaff(formData)
                .then(res => {
                    return res;
                })
                .catch(err => {
                    if (err) {
                        if (err.response.status === 400) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Gagal menambahkan staff!',
                            });
                        }
                        if (err.response.status === 409) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Email sudah terdaftar!',
                            });
                        }
                        if (err.response.status === 422) {
                            if (err?.response?.data?.errors?.length > 0) {
                                const errors = myHelper.getErrorMessages(err.response.data.errors);
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Mohon periksa kembali data yang anda masukkan!',
                                    html: errors.join('<br/>'),
                                });
                            }
                        }
                    }
                });
        } catch (err) {
            // some error handling
            console.log(err);
        }
    };

    if (detailStaf === null) {
        return (
            <>
                <Container className="main-home">
                    <div className='text-center pt-4'><h2>Staf Tidak Ditemukan</h2></div>
                    <Button variant='secondary' onClick={() => history.replace('/akun-manajemen/staff-manajemen')}>
                        <FontAwesomeIcon icon={faArrowLeft} /> &nbsp; Kembali
                    </Button>
                </Container>
            </>
        )
    }

    return (
        <div className="main-home">
            <div>
                <Card className="my-home-card">
                    <Card.Body>
                        <Container>
                            <Form onSubmit={submitUpdateStaff}>
                                <Row>
                                    <Col sm={12}>
                                        <Button variant='secondary' onClick={() => history.goBack()}>
                                            <FontAwesomeIcon icon={faArrowLeft} /> &nbsp; Kembali
                                        </Button>
                                    </Col>
                                    <Col sm={12}>
                                        <h4 className='mt-4'>Edit Staff</h4>
                                        <br />
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label><b>Nama Staff</b></Form.Label>
                                            <Form.Control name='nama_staf' type="text" placeholder="Masukkan nama staff..." defaultValue={detailStaf?.nama_user} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                            <Form.Label><b>Pilih Jabatan</b></Form.Label>
                                            <Form.Control
                                                as="select"
                                                name='id_jabatan'
                                                defaultValue={myJabatan ?? detailStaf?.my_child_jabatans?.id_child_jabatan}
                                                onChange={e => setJabatan(e.target.value)}
                                            >
                                                <option value='DEFAULT'>Pilih Jabatan...</option>
                                                {
                                                    listJabatan.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.id_jabatan}>{item.nama_jabatan}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                            <Form.Label><b>Nomor Telepon</b></Form.Label>
                                            <Form.Control name='no_telepon' type="number" min={0} placeholder="Masukkan nomor telepon..." defaultValue={detailStaf?.no_telepon} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                            <Form.Label><b>Tempat Lahir</b></Form.Label>
                                            <Form.Control name='tempat_lahir' type="text" placeholder="Masukkan tempat lahir..." defaultValue={detailStaf?.tempat_lahir} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                            <Form.Label><b>Tanggal Lahir</b></Form.Label>
                                            <Form.Control name='tanggal_lahir' type="date" placeholder="Masukkan tanggal lahir..." defaultValue={detailStaf?.tanggal_lahir} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                                            <Form.Label><b>Gelar</b></Form.Label>
                                            <Form.Control name='gelar' type="text" placeholder="Masukkan gelar atau kosongkan bila tidak ada..." defaultValue={detailStaf?.gelar} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <hr />
                                        <h5>Form Dibawah Ini Untuk Akses Aplikasi Oleh Staf</h5>
                                    </Col>
                                    <Col sm={12}>
                                        <input type="text" autoComplete='true' style={{ display: 'none' }} />
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput7">
                                            <Form.Label><b>Email</b></Form.Label>
                                            <Form.Control name='email' type="email" autoComplete='false' placeholder="Masukkan email staf..." defaultValue={detailStaf?.master_accounts[0]?.email} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput8">
                                            <Form.Label><b>Password Baru</b></Form.Label>
                                            <Form.Control name='password' type="password" autoComplete='new-password' placeholder="Masukkan password baru..." />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput9">
                                            <Form.Label><b>Konfirmasi Password Baru</b></Form.Label>
                                            <Form.Control name='confirm_password' type="password" autoComplete='new-password' placeholder="Masukkan konfirmasi password baru..." />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput10">
                                            <Form.Label><b>Ganti Foto Wajah</b></Form.Label><br />
                                            {
                                                uploadAvatar === null && (
                                                    <label
                                                        style={{
                                                            border: '1px solid #000',
                                                            color: '#0daaaa',
                                                            borderRadius: 5,
                                                            padding: '10px 15px 10px 15px',
                                                            cursor: 'pointer',
                                                            display: 'inline-block',
                                                            fontSize: 24,
                                                        }}
                                                    ><FontAwesomeIcon icon={faImage} />
                                                        <input id='imageUpload' hidden accept="image/png, image/jpeg, image/jpg"
                                                            multiple type="file" disabled={isFileLimit} onChange={changeAvatar} />
                                                    </label>
                                                )
                                            }
                                        </Form.Group>
                                        <div>
                                            {
                                                uploadAvatar ? (
                                                    <span>
                                                        <img
                                                            src={URL.createObjectURL(uploadAvatar)}
                                                            width={200}
                                                            height={200}
                                                            style={{ borderRadius: 5, marginRight: 10 }}
                                                            alt={`edit-avatar`}
                                                        />
                                                        <OverlayTrigger
                                                            placement="right"
                                                            overlay={
                                                                <Tooltip id="tooltip-right">
                                                                    Hapus Foto
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faTimes} style={{ color: 'red', cursor: 'pointer' }} onClick={() => setUploadAvatar(null)} />
                                                        </OverlayTrigger>
                                                    </span>
                                                ) : (
                                                    <span>
                                                        <img
                                                            src={detailStaf?.face_users[0]?.name_image ?? 'https://via.placeholder.com/200x200'}
                                                            width={200}
                                                            height={200}
                                                            style={{ borderRadius: 5, marginRight: 10 }}
                                                            alt={`edit-avatar`}
                                                        />
                                                    </span>
                                                )
                                            }
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <br />
                                        <Button type="submit" size="lg" variant="primary" disabled={isLoading}>
                                            {
                                                isLoading ?
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            role="status"
                                                            aria-hidden="true"
                                                            style={{
                                                                width: 20,
                                                                height: 20
                                                            }}
                                                        />&nbsp;
                                                    </>
                                                    :
                                                    <></>
                                            }
                                            Simpan
                                        </Button>
                                        <br /><br />
                                    </Col>
                                </Row>
                            </Form>
                        </Container>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default EditStaffView;
