import { useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import apiService from "../../../services/api.service";
import { useState } from "react";
import { async } from "@firebase/util";
import Swal from "sweetalert2";

const ProfilePage = () => {
    const path = useLocation();
    const history = useHistory();

    const [dataProfile, setDataProfile] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            await apiService.getDataProfile()
                .then(res => {
                    setDataProfile(res.data.data);
                })
                .catch(err => {
                    console.log(err);
                })
        }
        fetchData();
    }, []);

    const onSubmitProfile = async (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Data yang anda masukkan akan disimpan!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, simpan!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await apiService.updateProfile({
                    nama_user: e.target.nama.value,
                    no_telepon: e.target.nomor_telepon.value,
                    tempat_lahir: e.target.tempat_lahir.value,
                    tanggal_lahir: e.target.tanggal_lahir.value,
                    gelar: e.target.gelar.value
                })
                    .then(res => {
                        Swal.fire({
                            title: 'Berhasil!',
                            text: 'Data berhasil disimpan! Silahkan login kembali untuk melihat perubahan!',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        })
                    })
                    .catch(err => {
                        Swal.fire({
                            title: 'Gagal!',
                            text: 'Data gagal disimpan!',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    })
            }
        })
    }

    const onSubmitPassword = (e) => {
        e.preventDefault();
        if (e.target.password.value !== e.target.password_confirmation.value) {
            Swal.fire({
                title: 'Gagal!',
                text: 'Password baru tidak sama!',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return;
        }
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Data yang anda masukkan akan disimpan!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, simpan!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await apiService.updateAuthPimpinan({
                    email: e.target.email.value,
                    old_password: e.target.old_password.value,
                    password: e.target.password.value,
                    password_confirmation: e.target.password_confirmation.value
                })
                    .then(res => {
                        Swal.fire({
                            title: 'Berhasil!',
                            text: 'Data berhasil disimpan! Silahkan login kembali untuk melihat perubahan!',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        })
                    })
                    .catch(err => {
                        Swal.fire({
                            title: 'Gagal!',
                            text: 'Data gagal disimpan!',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    })
            }
        })
    }

    return (
        <div>
            <br />
            <Container>
                <Form onSubmit={onSubmitProfile}>
                    <Row>
                        <Col sm={12}>
                            <h5>#Form Dibawah Ini Untuk Merubah Informasi Akun Pimpinan</h5>
                            <hr />
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label><b>Nama</b></Form.Label>
                                <Form.Control name='nama' type="text" placeholder="Masukkan nama anda..." defaultValue={dataProfile?.nama_user} />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Label><b>Nomor Telepon</b></Form.Label>
                                <Form.Control name='nomor_telepon' type="text" placeholder="Masukkan nomor telepon anda..." defaultValue={dataProfile?.no_telepon} />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                <Form.Label><b>Tempat Lahir</b></Form.Label>
                                <Form.Control name='tempat_lahir' type="text" placeholder="Masukkan tempat lahir..." defaultValue={dataProfile?.tempat_lahir} />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                <Form.Label><b>Tanggal Lahir</b></Form.Label>
                                <Form.Control name='tanggal_lahir' type="date" placeholder="Masukkan tanggal lahir..." defaultValue={dataProfile?.tanggal_lahir} />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label><b>Gelar</b></Form.Label>
                                <Form.Control name='gelar' type="text" placeholder="Masukkan gelar atau kosongkan bila tidak ada..." defaultValue={dataProfile?.gelar} />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <br />
                            <Button variant='primary' type='submit'>
                                Simpan
                            </Button>
                            <br /><br />
                        </Col>
                    </Row>
                </Form>
                <br /><br />
                <Form onSubmit={onSubmitPassword}>
                    <Row>
                        <Col sm={12}>
                            <h5>#Form Dibawah Ini Untuk Merubah Password Akun Pimpinan</h5>
                            <hr />
                        </Col>
                        <Col sm={12}>
                            <input type="text" autoComplete='true' style={{ display: 'none' }} />
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                                <Form.Label><b>Email</b></Form.Label>
                                <Form.Control name='email' type="email" placeholder="Masukkan email anda..." autoComplete="email" required />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput7">
                                <Form.Label><b>Password Lama</b></Form.Label>
                                <Form.Control name='old_password' type="password" autoComplete='new-password' placeholder="Masukkan password lama..." required />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput8">
                                <Form.Label><b>Password Baru</b></Form.Label>
                                <Form.Control name='password' type="password" autoComplete='new-password' placeholder="Masukkan password baru..." required />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput9">
                                <Form.Label><b>Konfirmasi Password Baru</b></Form.Label>
                                <Form.Control name='password_confirmation' type="password" autoComplete='new-password' placeholder="Masukkan konfirmasi password baru..." required />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <br />
                            <Button variant='primary' type='submit'>
                                Simpan
                            </Button>
                            <br /><br />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    )
}

export default ProfilePage;