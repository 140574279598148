import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { Card, Col, Container, Row } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const PimpinanView = (props) => {
    const { t } = useTranslation();
    const [dt, setDt] = useState(moment().format('h:mm:ss A'));
    const [viewList, setViewList] = useState(0);

    useEffect(() => {
        let secTimer = setInterval(() => {
            setDt(moment().format('h:mm:ss A'))
        }, 1000)

        return () => clearInterval(secTimer);
    }, []);

    return (
        <div className="main-home">
            <div>
                <Card className="my-home-card">
                    <Card.Body>
                        <Container>
                            <div className="my-card-indent">
                                <div>
                                    <div className="child-time-left" style={{ background: '#4dbb4d' }}>
                                        {t('kantor.my_waktu')}
                                    </div>
                                    <div className="child-time-right">
                                        <FontAwesomeIcon icon={faClock} />
                                    </div>
                                </div>
                                <h6 style={{
                                    textTransform: 'capitalize',
                                }}>{dt}</h6>
                                <h6>{moment().format('dddd, Do MMMM YYYY')}</h6>
                            </div>
                            <Row>
                                <Col>
                                    <div className="my-card-indent">
                                        <div>
                                            <div className="child-time-left" style={{ background: '#9fc5e8' }}>
                                                {t('kantor.total_hadir')}
                                            </div>
                                        </div>
                                        <div className='p-4'>
                                            <h4>{props?.userData?.dashboard?.total_hadir ?? '-'}</h4>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="my-card-indent">
                                        <div>
                                            <div className="child-time-left" style={{ background: '#9fc5e8' }}>
                                                {t('kantor.total_belum_hadir')}
                                            </div>
                                        </div>
                                        <div className='p-4'>
                                            <h4>{props?.userData?.dashboard?.total_tidak_hadir ?? '-'}</h4>
                                            <div className='btn btn-info btn-md' onClick={() => setViewList(viewList === 0 ? 1 : 0)}>{viewList === 0 ? 'Lihat' : 'Tutup'}</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={12} lg={12}>
                                    <div className="my-card-indent">
                                        <div>
                                            <div className="child-time-left" style={{ background: '#9fc5e8' }}>
                                                {t('kantor.mengajukan_izin')}
                                            </div>
                                        </div>
                                        <div className='p-4'>
                                            <h4>{props?.userData?.dashboard?.total_izin ?? '-'}</h4>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12}>
                                    <hr />
                                    {
                                        viewList === 0 ?
                                            props?.userData?.data?.map((item, index) => {
                                                return (
                                                    <Card key={index} style={{ borderRadius: 13, backgroundColor: item?.is_izin ? '#f8c4b4' : '#aacfcf', margin: '15px 0px 15px 0px' }} border={false}>
                                                        <Card.Body className='d-flex flex-row justify-content-between w-100'>
                                                            <div>
                                                                <div>
                                                                    <h5>{item?.nama_user}</h5>
                                                                </div>
                                                                <div>
                                                                    <h6>{item?.is_izin ? item?.status_izin : item?.check_in + ' WIB'}</h6>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h5>{item?.['jenis_absen.nama']}</h5>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            })
                                            : viewList === 1 ?
                                                props?.userData?.no_presensi?.map((item, index) => {
                                                    return (
                                                        <Card key={index} style={{ borderRadius: 13, backgroundColor: '#c49b8e', margin: '15px 0px 15px 0px' }} border={false}>
                                                            <Card.Body className='d-flex flex-row justify-content-between w-100'>
                                                                <div>
                                                                    <div>
                                                                        <h5>{item?.nama_user}</h5>
                                                                    </div>
                                                                    <div>
                                                                        <h6>{item?.nama_jabatan}</h6>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <h5><i>Belum Melakukan Presensi!</i></h5>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    )
                                                })
                                                :
                                                <div className='text-center'>
                                                    <h3>
                                                        Data Tidak Ada!
                                                    </h3>
                                                </div>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default PimpinanView;
