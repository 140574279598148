import { useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Container, Form, Nav } from "react-bootstrap";
import styles from '../css/LandingPage.module.css';
import phoneImage from '../img/phone.png';

const LandingPage = () => {
    const path = useLocation();
    const history = useHistory();

    const handleLogin = () => {
        history.push('/login');
    }

    useEffect(() => {
        document.body.classList.add(styles.body);
        return () => {
            document.body.classList.remove(styles.body);
        };
    }, []);
    return (
        <div>
            <Navbar className={styles.navbar} expand="lg">
                <Container fluid>
                    <Navbar.Brand href="#" className={styles.brand}>Presensi</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto"
                            navbarScroll
                        ></Nav>
                        <Form className="d-flex">
                            <Button variant="outline-success" size="lg" onClick={() => handleLogin()}>Login</Button>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container>
                <div className={styles.wrapper_landing}>
                    <div className={styles.title_landing}>Presensi</div>
                    <div className={styles.deskripsi_landing}>
                        <p>
                            Disiplin Bekerja<br />
                            Hasil Terbaik<br />
                            Kesehatan Terjaga<br />
                        </p>
                    </div>
                    <Button variant="success" onClick={() => handleLogin()}>Login</Button>
                </div>
                <img src={phoneImage} alt="phone" className={styles.phone} />
                <div className={styles.circle1}></div>
                <div className={styles.circle2}></div>
                <div className={styles.circle3}></div>
                <div className={styles.bar1}></div>
                <div className={styles.bar2}></div>
            </Container>
        </div>
    )
}

export default LandingPage;