import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown, faClock, faExclamationTriangle, faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Col, Container, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Swal from 'sweetalert2'
import MyApi from '../../../api/MyApi';
import DatePicker from 'react-datepicker';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router-dom';

const ListDivisiView = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [modalShowTambah, setModalShowTambah] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [dataDivisi, setDataDivisi] = useState(null);
    const [listDivisi, setListDivision] = useState([]);
    const [infoAbsent, setInfoAbsent] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await apiService.getDivision()
                    .then(res => {
                        setListDivision(res.data.data);
                    });
            } catch (err) {
                // some error handling
                console.log(err);
            }
        };

        fetchData();
    }, []);

    const getDivision = async () => {
        try {
            await apiService.getDivision()
                .then(res => {
                    setListDivision(res.data.data);
                });
        } catch (err) {
            // some error handling
            console.log(err);
        }
    };

    const deleteDivision = async (data) => {
        try {
            Swal.fire({
                title: 'Apakah anda yakin?',
                text: "Data yang dihapus tidak dapat dikembalikan!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, hapus!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await apiService.deleteDivision(data?.id_jabatan)
                        .then(res => {
                            getDivision();
                            Swal.fire(
                                'Terhapus!',
                                'Data berhasil dihapus.',
                                'success'
                            )
                        });
                }
            })
        } catch (err) {
            // some error handling
            console.log(err);
        }
    };

    const openEditModal = (data) => {
        setDataDivisi(data);
        setModalShowEdit(true);
    }

    return (
        <div className="main-home">
            <div>
                <Card className="my-home-card">
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col sm={12} className='d-flex flex-row justify-content-between align-items-center'>
                                    <Button variant='success' className='mb-4 font-bold text-white' onClick={() => setModalShowTambah(true)}>
                                        Tambah Divisi
                                    </Button>
                                    <div style={{ color: '#db4d69' }}>
                                        <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;Tombol <strong>Hapus </strong>akan mati, bila masih terdapat <strong>Staff</strong> pada divisi tersebut.
                                    </div>
                                </Col>
                                <Col sm={12}>
                                    {
                                        !_.isEmpty(listDivisi) ?
                                            listDivisi?.map((item, index) => {
                                                return (
                                                    <Card key={index} style={{ color: 'white', borderRadius: 13, backgroundColor: '#407986', margin: '15px 0px 15px 0px' }} border={false}>
                                                        <Card.Body className='d-flex flex-row justify-content-between w-100'>
                                                            <div>
                                                                <div>
                                                                    <h5>{item?.nama_jabatan}</h5>
                                                                </div>
                                                                <div>
                                                                    <h6>{item?.count_user}&nbsp;Staff</h6>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Button variant='warning' onClick={() => openEditModal(item)}>
                                                                    Edit
                                                                </Button> &nbsp;
                                                                <OverlayTrigger
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top`}>
                                                                            Karena memiliki <strong>staf kosong</strong>, divisi dapat <strong>dihapus</strong>.
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Button variant='danger' onClick={() => deleteDivision(item)} disabled={item?.count_user != 0}>
                                                                        Hapus
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            })
                                            :
                                            <div className='text-center'>
                                                <h3>
                                                    Data Tidak Ada!
                                                </h3>
                                            </div>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </div>

            <ModalTambahDivisi
                show={modalShowTambah}
                onHide={() => setModalShowTambah(false)}
                trigger_get_division={(e) => getDivision()}
            />

            <ModalEditDivisi
                show={modalShowEdit}
                data_divisi={dataDivisi}
                onHide={() => setModalShowEdit(false)}
                trigger_get_division={(e) => getDivision()}
            />
        </div>
    );
};

function ModalTambahDivisi(props) {

    const addDivision = async (e) => {
        e.preventDefault();

        try {
            const data = {
                nama_divisi: e.target.nama_divisi.value,
            }
            await apiService.createDivision({
                ...data
            })
                .then(res => {
                    Swal.fire(
                        'Berhasil!',
                        'Data berhasil ditambahkan.',
                        'success'
                    )
                    .then(() => {
                        props.onHide();
                        props.trigger_get_division();
                    })
                });
        } catch (err) {
            // some error handling
            console.log(err);
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Tambah Divisi
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={addDivision}>
                <Modal.Body>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Nama Divisi</Form.Label>
                        <Form.Control type="text" placeholder="Masukkan Nama Divisi" name="nama_divisi" />
                        <Form.Text className="text-muted">
                            Nama divisi masih dapat diubah namanya kembali.
                        </Form.Text>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide} variant='danger'>Batal</Button>
                    <Button variant="success" type="submit">Simpan</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

function ModalEditDivisi(props) {

    const editDivision = async (e) => {
        e.preventDefault();

        try {
            const data = {
                id_divisi: props.data_divisi?.id_jabatan,
                nama_divisi: e.target.nama_divisi.value,
            }
            await apiService.putDivision({
                ...data
            })
                .then(res => {
                    Swal.fire(
                        'Berhasil!',
                        'Data berhasil diubah.',
                        'success'
                    )
                    .then(() => {
                        props.onHide();
                        props.trigger_get_division();
                    })
                });
        } catch (err) {
            // some error handling
            console.log(err);
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Divisi
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={editDivision}>
                <Modal.Body>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Nama Divisi</Form.Label>
                        <Form.Control type="text" placeholder="Masukkan Nama Divisi" name="nama_divisi" defaultValue={props?.data_divisi?.nama_jabatan} />
                        <Form.Text className="text-muted">
                            Nama divisi masih dapat diubah namanya kembali.
                        </Form.Text>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide} variant='danger'>Batal</Button>
                    <Button variant="warning" type="submit">Ubah</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default ListDivisiView;
