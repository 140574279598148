import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faChevronCircleDown, faClock, faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Container, Dropdown, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { DateRangePicker } from "react-date-range";
import { parseISO } from "date-fns";
import { id as localeID } from "date-fns/esm/locale";
import moment from 'moment';
import Swal from 'sweetalert2'
import myHelper from '../../../helpers';
import { forwardRef } from 'react';
import ChartLine from '../../../components/chart/ChartLine';
import ChartGauge from '../../../components/chart/ChartGauge';
import MonthlyTable from '../../../components/widgets/MonthlyTable';
import TableListAbsent from '../../../components/widgets/TableListAbsent';
import axios from 'axios';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const RekapView = () => {
    const location = useLocation();

    const myYear = new Date().getFullYear();
    const myMonth = new Date().getMonth();
    const [dateRange, setDateRange] = useState([`${myYear}0101`, null]);
    const [listChart1, setListChart1] = useState([]);
    const [listChart2, setListChart2] = useState([]);
    const [listTable3, setListTable3] = useState([]);
    const [listTable4, setListTable4] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [startDate3, setStartDate3] = useState(new Date(myYear, myMonth, 1));
    const [startDateNow, setStartDateNow] = useState(new Date(myYear, myMonth, 1));
    const [state, setState] = useState([
        {
            startDate: parseISO(`${myYear}0101`),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const [filterDate, setFilterDate] = useState({
        startDate: moment(`${myYear}0101`).format("DD/MM/YYYY"),
        endDate: moment(new Date()).format("DD/MM/YYYY"),
        awalDate: moment(`${myYear}0101`).format("YYYY-MM-DD"),
        akhirDate: moment(new Date()).format("YYYY-MM-DD"),
    });

    const ExampleCustomInput3 = forwardRef(({ value, onClick }, ref) => (
        <Button size="md" variant="primary" onClick={onClick} ref={ref} className='mb-4'>
            {moment(value).format('MMMM YYYY')} &nbsp;
            <FontAwesomeIcon icon={faChevronCircleDown} />
        </Button>
    ));

    useEffect(() => {
        var isMounted = true;
        const _query = new URLSearchParams(location.search);
        const instanceAxios = axios.create({
            baseURL: process.env.REACT_APP_BASE_API,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": _query.get('token'),
                "x-api-key": process.env.REACT_APP_BASE_API_KEY
            },
        });
        instanceAxios.interceptors.response.use(
            (res) => {
              return res;
            },
            async (err) => {
              const originalConfig = err.config;
              if (originalConfig.url !== "/auth_service/auth" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Mohon Maaf',
                      text: 'Mohon Maaf Anda Tidak Memiliki Akses!'
                    })
                } else if (err.response.status === 403 && !originalConfig._retry) {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Mohon Maaf',
                    text: 'Mohon Maaf Anda Tidak Memiliki Akses!'
                  })
                } else if (err.response.status === 500 && !originalConfig._retry) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Mohon Maaf',
                    text: 'Sedang Terjadi Error Pada Server!'
                  })
                }
              }
              return Promise.reject(err);
            }
          );
        instanceAxios.get('/staf_service/main/rekap?date=' + moment(startDate).format('yyyy-MM-DD'))
            .then((res) => {
                if (isMounted) {
                    setListChart1(res?.data?.data);
                }
            });
        return () => { isMounted = false };
    }, [location.search, startDate]);

    useEffect(() => {
        var isMounted = true;
        const _query = new URLSearchParams(location.search);
        const instanceAxios = axios.create({
            baseURL: process.env.REACT_APP_BASE_API,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": _query.get('token'),
                "x-api-key": process.env.REACT_APP_BASE_API_KEY
            },
        });
        instanceAxios.get('/staf_service/main/rekap/between?date1=' + moment(dateRange[0] ?? new Date()).format('yyyy-MM-DD') + '&date2=' + moment(dateRange[1] ?? new Date()).format('yyyy-MM-DD'))
            .then((res) => {
                if (isMounted) {
                    setListChart2(res?.data?.data);
                }
            });
        return () => { isMounted = false };
    }, [location.search, dateRange]);

    useEffect(() => {
        const _query = new URLSearchParams(location.search);
        const instanceAxios = axios.create({
            baseURL: process.env.REACT_APP_BASE_API,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": _query.get('token'),
                "x-api-key": process.env.REACT_APP_BASE_API_KEY
            },
        });
        const fetchData = async () => {
            const currentDate = new Date(); // Mendapatkan tanggal saat ini
            const firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            await instanceAxios.get('/staf_service/main/rekap/per-bulan?date=' + moment(firstDateOfMonth).format('yyyy-MM-DD'))
                .then((res) => {
                    const dataFormated = myHelper.formatedPerMonth(firstDateOfMonth, res?.data?.data);
                    const dataFormatedLive = myHelper.formatedPerMonthHadir(firstDateOfMonth, res?.data?.data);
                    setListTable3(dataFormated);
                    setListTable4(dataFormatedLive);
                });
        }
        fetchData();
    }, [location.search]);

    const refreshInfo3 = async (date) => {
        setStartDate3(date);
        const _query = new URLSearchParams(location.search);
        const instanceAxios = axios.create({
            baseURL: process.env.REACT_APP_BASE_API,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": _query.get('token'),
                "x-api-key": process.env.REACT_APP_BASE_API_KEY
            },
        });
        await instanceAxios.get('/staf_service/main/rekap/per-bulan?date=' + moment(date).format('yyyy-MM-DD'))
            .then((res) => {
                const dataFormated = myHelper.formatedPerMonth(date, res?.data?.data);
                setListTable3(dataFormated);
            });
    };

    const myDateRange = async (data) => {
        setState([data.selection]);
        setFilterDate({
            startDate: moment(data.selection.startDate).format("DD/MM/YYYY"),
            endDate: moment(data.selection.endDate).format("DD/MM/YYYY"),
            awalDate: moment(data.selection.startDate).format("YYYY-MM-DD"),
            akhirDate: moment(data.selection.endDate).format("YYYY-MM-DD"),
        });
        const _query = new URLSearchParams(location.search);
        const instanceAxios = axios.create({
            baseURL: process.env.REACT_APP_BASE_API,
            headers: {
                "Content-Type": "application/json",
                "x-access-token": _query.get('token'),
                "x-api-key": process.env.REACT_APP_BASE_API_KEY
            },
        });
        await instanceAxios.get('/staf_service/main/rekap/between?date1=' + moment(data.selection.startDate ?? new Date()).format('yyyy-MM-DD') + '&date2=' + moment(data.selection.endDate ?? new Date()).format('yyyy-MM-DD'))
            .then((res) => {
                setListChart2(res?.data?.data);
            });
    };

    return (
        <div className="main-home">
            <Container>
                <Card className='mt-4'>
                    <Card.Header>
                        <h4>Rekap Presensi Kehadiran</h4>
                    </Card.Header>
                    <Card.Body>
                        {/* <ChartGauge data={listChart1} id="chart1" /> */}
                        <TableListAbsent dateSelected={startDateNow} attendanceData={listTable4} />
                    </Card.Body>
                </Card>
                <br />
                <Card>
                    <Card.Header>
                        <h4>Rekap Periode Presensi Kehadiran</h4>
                    </Card.Header>
                    <Card.Body>
                        <Dropdown drop="down" align="left" id="dropdown-menu-align-end">
                            <Dropdown.Toggle className="my-dropdown" id="dropdown-basic">
                                <span>
                                    <FontAwesomeIcon icon={faCalendar} />
                                </span>
                                &nbsp; {filterDate.startDate} - {filterDate.endDate} &nbsp;
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ marginTop: 5 }}>
                                <DateRangePicker
                                    onChange={(item) => myDateRange(item)}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    direction="horizontal"
                                    locale={localeID}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                        <br />
                        {
                            listChart2 && listChart2.length > 0 ?
                                <ChartLine data={listChart2} id="chart2" name_x="nama_user" />
                                :
                                <><h4>Data Kosong!</h4></>
                        }
                    </Card.Body>
                </Card>
                <br />
                <Card>
                    <Card.Header>
                        <h4>Rekap Per-bulan Presensi Kehadiran</h4>
                    </Card.Header>
                    <Card.Body>
                        <DatePicker
                            selected={startDate3}
                            onChange={(date) => refreshInfo3(date)}
                            customInput={<ExampleCustomInput3 />}
                            showMonthYearPicker
                        />
                        <MonthlyTable dateSelected={startDate3} attendanceData={listTable3} showDownload={false}/>
                    </Card.Body>
                </Card>
                <br />
            </Container>
        </div>
    );
};

export default RekapView;
