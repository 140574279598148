import axios from "axios";

const login = async (email, password, tokenFCM = "") => {
    const response = await axios
        .post(process.env.REACT_APP_BASE_API + "/auth_service/auth", {
            email: email,
            password: password,
            token: tokenFCM
        }, {
            headers: {
                'x-api-key': process.env.REACT_APP_BASE_API_KEY
            },
        });
    return response.data;
};
const logout = () => {
    localStorage.removeItem("user");
};
const AuthService = {
    login,
    logout
};
export default AuthService;
