import { useEffect, useContext } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import apiService from "../../services/api.service";
import { useState } from "react";
import _ from "lodash";
import moment from "moment";
import PresenceContext from '../../context/PresenceContext';

const NotificationPage = () => {
    const path = useLocation();
    const history = useHistory();
    const { remember } = useContext(PresenceContext);

    const [listNotif, setListNotif] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await apiService.getListNotif(remember.level_int, '')
                .then(res => {
                    setListNotif(res.data.grouped);
                })
                .catch(err => {
                    console.log(err);
                })
        }

        fetchData();
    }, [remember.level_int]);

    const getAllNotif = async () => {
        await apiService.getListNotif(remember.level_int, '')
        .then(res => {
            setListNotif(res.data.grouped);
        })
        .catch(err => {
            console.log(err);
        })
    }

    const notifClicked = async (item, clicked_val = 1) => {
        await apiService.putNotifRead(remember.level_int, {
            is_viewed: clicked_val,
            id_log_notification: item?.dataValues?.id_log_notification
        })
        .then(res => {
            if(item?.dataValues?.routes_to === null){
                getAllNotif();
            }
            window.location.href = item?.dataValues?.routes_to ?? '/notification';
        })
        .catch(err => {
            console.log(err);
        })
    }

    return (
        <div>
            <br />
            <Container>
                <Row>
                    <Col sm={12} style={{ paddingBottom: 20 }}>
                        <Button variant='secondary' onClick={() => history.goBack()}>
                            <FontAwesomeIcon icon={faArrowLeft} /> &nbsp; Kembali
                        </Button>
                    </Col>
                    <Col sm={12}>
                        {
                            _.isEmpty(listNotif) ?
                                <div className='d-flex flex-row justify-content-center'>
                                    <h5>Tidak ada notifikasi</h5>
                                </div>
                                :
                                listNotif.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <h5 style={{ color: '#000' }}>{moment(item?.date).format('MMMM YYYY')}</h5>
                                            {
                                                _.isEmpty(item?.values) ?
                                                    <div className='d-flex flex-row justify-content-center'>
                                                        <h5>Tidak ada notifikasi</h5>
                                                    </div>
                                                    :
                                                    item?.values.map((item2, index2) => {
                                                        return (
                                                            <Card
                                                                key={index2}
                                                                style={{
                                                                    borderRadius: 13,
                                                                    backgroundColor: item2?.dataValues?.is_viewed ? '#aacfcf' : '#fde2e2',
                                                                    margin: '15px 0px 15px 0px',
                                                                    cursor: item2?.dataValues?.is_viewed ? 'default' : 'pointer'
                                                                }}
                                                                border={false}
                                                                onClick={() => notifClicked(item2, 1)}
                                                            >
                                                                <Card.Body className='d-flex flex-row justify-content-between w-100 my-list-notif'>
                                                                    <div>
                                                                        <div>
                                                                            <h5>{item2?.dataValues?.title}</h5>
                                                                        </div>
                                                                        <div>
                                                                            <h6>{item2?.dataValues?.message}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h6>{moment(item2?.t).format('DD MMMM YYYY HH:mm:ss')}</h6>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        )
                                                    })
                                            }
                                        </div>
                                    )
                                })
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default NotificationPage;