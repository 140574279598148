// React Component
import React from "react";

const Settings = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ textAlign: 'center' }}>
                <h1>404</h1>
                <h2>Page Not Found</h2>
            </div>
        </div>
    );
};

export default Settings;