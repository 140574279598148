import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChevronCircleDown, faClock, faCross, faImage, faSearchLocation, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Badge, Button, Card, Col, Container, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker';
import apiService from '../../../services/api.service';
import myHelper from '../../../helpers/index';
import { useHistory } from 'react-router-dom';

const AddStaffView = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [viewList, setViewList] = useState(0);
    const [listJabatan, setListJabatan] = useState([]);
    const [uploadAvatar, setUploadAvatar] = useState(null);
    const [imageLimit, setImageLimit] = useState(1);
    const [isFileLimit, setIsFileLimit] = useState(false);
    const [infoAbsent, setInfoAbsent] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await apiService.getRefJabatan()
                    .then(res => {
                        setListJabatan(res.data.data);
                    });
            } catch (err) {
                console.log(err);
            }
        };

        fetchData();
    }, []);

    const changeAvatar = (e) => {
        setUploadAvatar(e.target.files[0]);
    }

    const addStaff = async (e) => {
        e.preventDefault();
        if(uploadAvatar === null) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Foto tidak boleh kosong!',
            });
            return;
        }
        if(e.target.password.value !== e.target.confirm_password.value) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Password tidak sama!',
            });
            return;
        }
        try {
            const formData = new FormData();
            formData.append('image', uploadAvatar);
            formData.append('nama_user', e.target.nama_staf.value);
            formData.append('id_jabatan', e.target.id_jabatan.value);
            formData.append('no_telepon', e.target.no_telepon.value);
            formData.append('tanggal_lahir', e.target.tanggal_lahir.value);
            formData.append('tempat_lahir', e.target.tempat_lahir.value);
            formData.append('email', e.target.email.value);
            formData.append('password', e.target.password.value);
            formData.append('gelar', e.target.gelar.value);

            await apiService.createStaff(formData)
                .then(res => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Berhasil!',
                        text: 'Staff berhasil ditambahkan!',
                    })
                    .then(() => {
                        history.replace('/akun-manajemen/staff-manajemen');
                    })
                })
                .catch(err => {
                    if(err){
                        if(err.response.status === 400) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Gagal menambahkan staff!',
                            });
                        }
                        if(err.response.status === 409) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Email sudah terdaftar!',
                            });
                        }
                        if(err.response.status === 422){
                            if (err?.response?.data?.errors?.length > 0) {
                                const errors = myHelper.getErrorMessages(err.response.data.errors);
                                Swal.fire({
                                  icon: 'error',
                                  title: 'Mohon periksa kembali data yang anda masukkan!',
                                  html: errors.join('<br/>'),
                                });
                            }
                        }
                    }
                });
        } catch (err) {
            // some error handling
            console.log(err);
        }
    };

    return (
        <div className="main-home">
            <div>
                <Card className="my-home-card">
                    <Card.Body>
                        <Container>
                            <Form onSubmit={addStaff}>
                                <Row>
                                    <Col sm={12}>
                                        <Button variant='secondary' onClick={() => history.goBack()}>
                                            <FontAwesomeIcon icon={faArrowLeft} /> &nbsp; Kembali
                                        </Button>
                                    </Col>
                                    <Col sm={12}>
                                        <h4 className='mt-4'>Tambah Staff</h4>
                                        <br />
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label><b>Nama Staff</b></Form.Label>
                                            <Form.Control name='nama_staf' type="text" placeholder="Masukkan nama staff..." />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                            <Form.Label><b>Pilih Jabatan</b></Form.Label>
                                            <Form.Control as="select" name='id_jabatan'>
                                                <option value=''>Pilih Jabatan...</option>
                                                {
                                                    listJabatan.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.id_jabatan}>{item.nama_jabatan}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                            <Form.Label><b>Nomor Telepon</b></Form.Label>
                                            <Form.Control name='no_telepon' type="number" min={0} placeholder="Masukkan nomor telepon..." />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                            <Form.Label><b>Tempat Lahir</b></Form.Label>
                                            <Form.Control name='tempat_lahir' type="text" placeholder="Masukkan tempat lahir..." />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                            <Form.Label><b>Tanggal Lahir</b></Form.Label>
                                            <Form.Control name='tanggal_lahir' type="date" placeholder="Masukkan tanggal lahir..." />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                                            <Form.Label><b>Gelar</b></Form.Label>
                                            <Form.Control name='gelar' type="text" placeholder="Masukkan gelar atau kosongkan bila tidak ada..." />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <hr />
                                        <h5>Form Dibawah Ini Untuk Akses Aplikasi Oleh Staf</h5>
                                    </Col>
                                    <Col sm={12}>
                                        <input type="text" autoComplete='true' style={{ display: 'none' }} />
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput7">
                                            <Form.Label><b>Email</b></Form.Label>
                                            <Form.Control name='email' type="email" autoComplete='false' placeholder="Masukkan email staf..." />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput8">
                                            <Form.Label><b>Password</b></Form.Label>
                                            <Form.Control name='password' type="password" autoComplete='new-password' placeholder="Masukkan password..." />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput9">
                                            <Form.Label><b>Konfirmasi Password</b></Form.Label>
                                            <Form.Control name='confirm_password' type="password" autoComplete='new-password' placeholder="Masukkan konfirmasi password..." />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput10">
                                            <Form.Label><b>Upload Foto Wajah</b></Form.Label><br />
                                            {
                                                uploadAvatar === null && (
                                                    <label
                                                        style={{
                                                            border: '1px solid #000',
                                                            color: '#0daaaa',
                                                            borderRadius: 5,
                                                            padding: '10px 15px 10px 15px',
                                                            cursor: 'pointer',
                                                            display: 'inline-block',
                                                            fontSize: 24,
                                                        }}
                                                    ><FontAwesomeIcon icon={faImage} />
                                                        <input id='imageUpload' hidden accept="image/png, image/jpeg, image/jpg"
                                                            multiple type="file" disabled={isFileLimit} onChange={changeAvatar} />
                                                    </label>
                                                )
                                            }
                                        </Form.Group>
                                        <div>
                                            {
                                                uploadAvatar && (
                                                    <span>
                                                        <img
                                                            src={URL.createObjectURL(uploadAvatar)}
                                                            width={200}
                                                            height={200}
                                                            style={{ borderRadius: 5, marginRight: 10 }}
                                                            alt={`avatar`}
                                                        />
                                                        <OverlayTrigger
                                                            placement="right"
                                                            overlay={
                                                                <Tooltip id="tooltip-right">
                                                                    Hapus Foto
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faTimes} style={{ color: 'red', cursor: 'pointer' }} onClick={() => setUploadAvatar(null)} />
                                                        </OverlayTrigger>
                                                    </span>
                                                )
                                            }
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <br />
                                        <Button variant='primary' type='submit'>
                                            Simpan
                                        </Button>
                                        <br /><br />
                                    </Col>
                                </Row>
                            </Form>
                        </Container>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default AddStaffView;
