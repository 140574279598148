import React, { useState, useEffect, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown, faClock, faTrash, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Col, Container, Row, Modal, ListGroup, ButtonGroup } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker';
import useFCM from '../../../hooks/useFCM';
import apiService from '../../../services/api.service';
import Skeleton from 'react-loading-skeleton'

const RiwayatIzinView = (props) => {
    const { t } = useTranslation();
    const [_payload] = useFCM();
    const [isLoading, setIsLoading] = useState(false);
    const [infoAbsent, setInfoAbsent] = useState(null);
    const [listPegawai, setListPegawai] = useState([]);
    const [myView, setMyView] = useState(0);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [startDate, setStartDate] = useState(new Date());

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button size="lg" variant="primary" onClick={onClick} ref={ref}>
            {moment(value).format('dddd, Do MMMM YYYY')} &nbsp;
            <FontAwesomeIcon icon={faChevronCircleDown} />
        </Button>
    ));

    useEffect(() => {
        setIsLoading(true);
        async function fetchData() {
            await apiService.getDataDL(`?status=${myView}&date=${moment(startDate).format('yyyy-MM-DD')}`)
                .then((res) => {
                    setTimeout(() => {
                        setIsLoading(false);
                        setInfoAbsent(res?.data);
                    }, 1000);
                });

            await apiService.getDataDLLeft(`?status=${myView}&date=${moment(startDate).format('yyyy-MM-DD')}`)
                .then((res) => {
                    // delay 3 detik
                    setListPegawai(res?.data?.data);
                });
        }
        fetchData();
    }, [_payload, myView, startDate]);

    const showByDate = async (date) => {
        setStartDate(date);
        await apiService.getDataIzin(`?status=${myView}&date=${moment(date).format('yyyy-MM-DD')}`)
            .then((res) => {
                setInfoAbsent(res?.data);
            })
            .catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    text: err.response.data.message,
                    icon: 'error'
                })
            });
    }

    const handleCheckboxChange = (id_user) => {
        setSelectedUsers((prevSelected) =>
            prevSelected.includes(id_user)
                ? prevSelected.filter((id) => id !== id_user)
                : [...prevSelected, id_user]
        );
    };

    const showModalDetail = async (e) => {
        await apiService.deleteDataDL(`/${e}`)
            .then(res => {
                setMyView(myView + 1)
            })
            .catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    text: err.response.data.message,
                    icon: 'error'
                })
            });
    }

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        await apiService.addDataDL({
            list_user: selectedUsers,
            date: startDate
        })
            .then(res => {
                setSelectedUsers([]);
                setMyView(myView + 1)
            })
            .catch(err => {
                Swal.fire({
                    title: 'Gagal!',
                    text: err.response.data.message,
                    icon: 'error'
                })
            });
    };

    return (
        <div className="main-home">
            <br />
            <Container>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => showByDate(date)}
                    customInput={<ExampleCustomInput />}
                />
            </Container>
            <Card className="my-home-card">
                <Card.Body>
                    <Container>
                        <Row>
                            <Col>
                                <h4 className='text-center'>Tambah Staff Untuk Dinas Luar</h4>
                                <form onSubmit={handleSubmit}>
                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        {listPegawai.map((pegawai, index) => (
                                            <div className="form-group form-check" key={pegawai.id_user} >
                                                <input type="checkbox" value={pegawai.id_user} className="form-check-input" id={index} checked={selectedUsers.includes(pegawai.id_user)} onChange={() => handleCheckboxChange(pegawai.id_user)} />
                                                <label className="form-check-label" style={{ fontSize: 18 }} htmlFor={index}>{pegawai.nama_user}</label>
                                            </div>
                                        ))}
                                    </ul>
                                    <button type="submit" className='btn btn-success'>Submit</button>
                                </form>
                            </Col>
                            <Col sm={12}>
                                <hr />
                                <h4 className='text-center mt-4'>List Pegawai Diperbolehkan Dinas Luar</h4>
                                {
                                    isLoading ?
                                        <Skeleton height={100} baseColor="#f3f3f3" highlightColor="#cccccc" borderRadius={10} count={3} style={{ marginTop: 10 }} />
                                        :
                                        !_.isEmpty(infoAbsent?.data) ? (
                                            <div
                                                style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns
                                                    gap: '15px', // space between cards
                                                }}
                                            >
                                                {infoAbsent?.data?.map((item, index) => (
                                                    <Card
                                                        key={index}
                                                        style={{
                                                            borderRadius: 13,
                                                            backgroundColor: '#aacfcf',
                                                            cursor: 'pointer',
                                                            width: '100%',
                                                        }}
                                                        border={false}
                                                        onClick={() => showModalDetail(item?.id_accept)}
                                                    >
                                                        <Card.Body
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <div
                                                                className='d-flex flex-column justify-content-center align-items-end'
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} size="md" color='#9c281f' />
                                                            </div>
                                                            <div className='d-flex flex-column align-items-center'>
                                                                <div>
                                                                    <FontAwesomeIcon icon={faUserAlt} size="lg" />
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <h6>{item?.user?.nama_user}</h6>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                ))}
                                            </div>
                                        )
                                            :
                                            <div className='text-center'>
                                                <br />
                                                <h3>
                                                    Data Tidak Ada!
                                                </h3>
                                            </div>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </div>
    );
};

export default RiwayatIzinView;
