import React, { useRef } from 'react';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';

const TableListAbsent = ({ dateSelected = new Date(), attendanceData = [] }) => {
    const tableRef = useRef(null);

    const myYear = dateSelected.getFullYear();
    const myMonth = dateSelected.getMonth();
    const numDays = new Date(myYear, myMonth + 1, 0).getDate();

    const setBackground = (day) => {
        let color = 'white';
        switch (day) {
            case 1:
                // Sudah Presensi
                color = '#aec99f';
                break;
            case 0:
                // Hari Sabtu
                color = '#cccccc';
                break;
            case 6:
                // Hari Minggu
                color = '#cccccc';
                break;
            default:
                // Belum Presensi
                color = '#ffebb2';
                break;
        };
        return color;
    }

    const renderTableHeader = () => {
        const tableHeader = [
            <th key="no">Tanggal</th>,
            <th key="masuk" style={{ whiteSpace: 'nowrap' }}>Masuk</th>,
            <th key="pulang" style={{ whiteSpace: 'nowrap' }}>Pulang</th>,
            <th key="izin">Izin</th>,
            <th key="terlamabat">Terlambat</th>
        ];
        return <tr>{tableHeader}</tr>;
    };

    const renderTableData = () => {
        return attendanceData.map((employee, index) => {
            const { attendance } = employee;
            const tableData = [];

            const currentDate = new Date(dateSelected);

            for (let i = 0; i < numDays; i++) {
                const date = new Date(currentDate);
                date.setDate(currentDate.getDate() + i);
                const formattedDate = moment(date).tz('Asia/Jakarta').format('DD');
                const attendanceStatus = attendance[i];

                const timeRequest = moment(attendanceStatus?.data?.attendance).format('HH:mm:ss');
                var a = moment(timeRequest, 'HH:mm:ss').tz("Asia/Jakarta");
                var b = moment(attendanceStatus?.data?.config_time_late, 'HH:mm:ss').tz("Asia/Jakarta");
                var diff_s = a.diff(b, 'seconds');
                const timeEnd = moment(attendanceStatus?.data?.config_time_late, 'HH:mm:ss').valueOf();
                const statusLimit = moment(timeRequest, 'HH:mm:ss').valueOf() > timeEnd ? 1 : 0;

                tableData.push(
                    <tr key={i + '__list'} style={{ backgroundColor: setBackground(attendanceStatus.status)}}>
                        <td key={i + '__tanggal'}>{formattedDate}</td>
                        <td key={i + '__status'}>{attendanceStatus?.data?.attendance && attendanceStatus?.data?.izin == null ? moment(attendanceStatus?.data?.attendance).format('HH:mm') : '00:00'}</td>
                        <td key={i + '__status_pulang'}>{attendanceStatus?.data?.attendance_out ? moment(attendanceStatus?.data?.attendance_out).format('HH:mm') : '00:00'}</td>
                        <td key={i + '__status_izin'}>{attendanceStatus?.data?.izin ? '1' : '0'}</td>
                        <td key={i + '__terlambat'}>{attendanceStatus?.data?.attendance && attendanceStatus?.data?.izin == null && statusLimit ? moment.utc(moment.duration(diff_s, "seconds").asMilliseconds()).format("HH [Jam] mm [Menit]") : '-'}</td>
                    </tr>
                );
            }

            return tableData;
        });
    };

    return (
        <div>
            <div className='d-flex justify-content-between w-100 mb-4'>
                <div></div>
                <h4 className='text-center'>Rekap {moment(dateSelected).format('MMMM YYYY')}</h4>
            </div>
            <Table responsive bordered ref={tableRef}>
                <thead>{renderTableHeader()}</thead>
                <tbody>{renderTableData()}</tbody>
            </Table>
        </div>
    );
};


export default TableListAbsent;