import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown, faClock, faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Swal from 'sweetalert2'
import MyApi from '../../../api/MyApi';
import DatePicker from 'react-datepicker';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router-dom';

const ListStaffView = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [viewList, setViewList] = useState(0);
    const [listStaff, setListStaff] = useState([]);
    const [infoAbsent, setInfoAbsent] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await apiService.getListStaff()
                    .then(res => {
                        setListStaff(res.data.data);
                    });
            } catch (err) {
                // some error handling
                console.log(err);
            }
        };

        fetchData();
    }, []);

    const getStaff = async () => {
        try {
            await apiService.getListStaff()
                .then(res => {
                    setListStaff(res.data.data);
                });
        } catch (err) {
            // some error handling
            console.log(err);
        }
    };

    const deleteStaff = async (data) => {
        try {
            Swal.fire({
                title: 'Apakah anda yakin?',
                text: "Data yang dihapus tidak dapat dikembalikan!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, hapus!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await apiService.deleteStaff({
                        ...data
                    })
                        .then(res => {
                            getStaff();
                            Swal.fire(
                                'Terhapus!',
                                'Data berhasil dihapus.',
                                'success'
                            )
                        });
                }
            })
        } catch (err) {
            // some error handling
            console.log(err);
        }
    };

    return (
        <div className="main-home">
            <div>
                <Card className="my-home-card">
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <Button variant='info' className='mb-4' onClick={() => history.push('/akun-manajemen/staff-manajemen/add')}>
                                        Tambah Staff
                                    </Button>
                                </Col>
                                <Col sm={12}>
                                    {
                                        !_.isEmpty(listStaff) ?
                                            listStaff?.map((item, index) => {
                                                return (
                                                    <Card key={index} style={{ borderRadius: 13, backgroundColor: '#aacfcf', margin: '15px 0px 15px 0px' }} border={false}>
                                                        <Card.Body className='d-flex flex-row justify-content-between w-100'>
                                                            <div>
                                                                <div>
                                                                    <h5>{item?.nama_user}</h5>
                                                                </div>
                                                                <div>
                                                                    <h6>{item?.nama_jabatan}</h6>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Button variant='warning' onClick={() => props.history.push(`/akun-manajemen/staff-manajemen/edit/${item?.id_user}`)}>
                                                                    Edit
                                                                </Button> &nbsp;
                                                                <Button variant='danger' onClick={() => deleteStaff(item)}>
                                                                    Hapus
                                                                </Button>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                )
                                            })
                                            :
                                            <div className='text-center'>
                                                <h3>
                                                    Data Tidak Ada!
                                                </h3>
                                            </div>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

export default ListStaffView;
