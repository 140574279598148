import moment from 'moment-timezone';
import { createBrowserHistory } from 'history'

class MyHelper {
    constructor() {
        this.name = 'MyHelper';
    }
    getName() {
        return this.name;
    }

    AddHistory() {
        const history = createBrowserHistory();
        console.log(history);
        return history;
    }
    // Other methods
    getErrorMessages(errors) {
        const errorMessages = [];
        for (const error of errors) {
            errorMessages.push(error.msg);
        }
        return errorMessages;
    }

    formatedPerMonth(dateSelected, dataPresensi = []) {
        const addDays = (date, days) => {
            const result = new Date(date);
            result.setDate(date.getDate() + days);
            return moment(result).tz('Asia/Jakarta').format('MM/DD/YYYY');
        };
        
        const isWeekend = (date) => {
            const result = new Date(date);
            const day = result.getDay();
            return day; // Mengembalikan true jika hari adalah Minggu (0) atau Sabtu (6)
        };
        
        const formattedData = dataPresensi.reduce((acc, item) => {
            const { name, attendance } = item;
            const date = new Date(dateSelected);
            const formattedDate = moment(date).tz('Asia/Jakarta').format('MM/DD/YYYY');
            const existingEntry = acc.find((entry) => entry.name === name);

            if (existingEntry) {
                existingEntry?.attendance?.push(formattedDate);
            } else {
                const myYear = date.getFullYear();
                const myMonth = date.getMonth();
                const numDays = new Date(myYear, myMonth + 1, 0).getDate(); // Jumlah hari dalam satu bulan
                const daysPresent = dataPresensi
                    .filter((entry) => entry.name === name)
                    .map((entry) =>
                        moment(entry.attendance).tz('Asia/Jakarta').format('MM/DD/YYYY')
                    );
                const attendanceArray = Array.from({ length: numDays }, (_, i) =>{
                    if(daysPresent.includes(addDays(date, i))){
                        return 1;
                    } else {
                        if(isWeekend(addDays(date, i)) === 0){
                            return 0;
                        } else if(isWeekend(addDays(date, i)) === 6){
                            return 6;
                        } else {
                            return 7;
                        }
                    }
                });
                acc.push({ name, attendance: attendanceArray });
            }
            return acc;
        }, []);
        return formattedData;
    }
    
    formatedPerMonthHadir(dateSelected, dataPresensi = []) {
        const addDays = (date, days) => {
            const result = new Date(date);
            result.setDate(date.getDate() + days);
            return moment(result).tz('Asia/Jakarta').format('MM/DD/YYYY');
        };
        
        const isWeekend = (date) => {
            const result = new Date(date);
            const day = result.getDay();
            return day; // Mengembalikan true jika hari adalah Minggu (0) atau Sabtu (6)
        };
        
        const formattedData = dataPresensi.reduce((acc, item) => {
            const { name, attendance } = item;
            const date = new Date(dateSelected);
            const formattedDate = moment(date).tz('Asia/Jakarta').format('MM/DD/YYYY');
            const existingEntry = acc.find((entry) => entry.name === name);

            if (existingEntry) {
                existingEntry?.attendance?.push(formattedDate);
            } else {
                const myYear = date.getFullYear();
                const myMonth = date.getMonth();
                const numDays = new Date(myYear, myMonth + 1, 0).getDate(); // Jumlah hari dalam satu bulan
                const daysPresent = dataPresensi
                    .filter((entry) => entry.name === name)
                    .map((entry) =>
                        moment(entry.attendance).tz('Asia/Jakarta').format('MM/DD/YYYY')
                    );
                const presentData = dataPresensi
                    .filter((entry) => entry.name === name)
                    .map((entry) =>
                        moment(entry.attendance).tz('Asia/Jakarta').format('MM/DD/YYYY')
                    );
                const attendanceArray = Array.from({ length: numDays }, (_, i) =>{
                    if(daysPresent.includes(addDays(date, i))){
                        return {
                            status: 1,
                            data: dataPresensi[presentData.findIndex((entry) => entry === addDays(date, i))]
                        };
                    } else {
                        if(isWeekend(addDays(date, i)) === 0){
                            return {
                                status: 0,
                                data: _
                            };
                        } else if(isWeekend(addDays(date, i)) === 6){
                            return {
                                status: 6,
                                data: _
                            };
                        } else {
                            return {
                                status: 7,
                                data: _
                            };
                        }
                    }
                });
                acc.push({ name, attendance: attendanceArray });
            }
            return acc;
        }, []);
        return formattedData;
    }
}

export default new MyHelper();