import 'firebase/messaging'
import * as firebase from 'firebase/app'
import * as messaging from "firebase/messaging";
import { getMessaging } from "firebase/messaging";
import localforage from 'localforage'
// import { getAnalytics } from "firebase/analytics";

const firebaseCloudMessaging = {
    tokenInlocalforage: async () => {
        return localforage.getItem('fcm_token')
    },

    initCondition: () => {
        return firebase.getApps().length;
    },

    init: async function () {
        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FCM_API_KEY,
            authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_FCM_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FCM_APP_ID,
            measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID
        };

        try {
            if (!this.initCondition()) {
                firebase.initializeApp(firebaseConfig);
            }

            const messagingApp = getMessaging();

            messaging.onMessage(function (payload) {
                const notificationTitle = payload.notification.title;
                const notificationOptions = {
                    body: payload.notification.body,
                    icon: payload.notification.icon,
                };
                // console.log(notificationTitle,notificationOptions)

                if (!("Notification" in window)) {
                    console.log("This browser does not support system notifications.");
                } else if (Notification.permission === "granted") {
                    // If it's okay let's create a notification
                    var notification = new Notification(notificationTitle, notificationOptions);
                    notification.onclick = function (event) {
                        event.preventDefault();
                        window.open(payload.notification.click_action, '_blank');
                        notification.close();
                    }
                }
            })
            await Notification.requestPermission()
            await messaging.getToken(messagingApp, { vapidKey: process.env.REACT_APP_FCM_VAPID_KEY })
                .then(async (t) => {
                    localforage.setItem('fcm_token', t);
                    // const topicURL = `https://iid.googleapis.com/iid/v1/${t}/rel/topics/pimpinan`;
                    // await axios({
                    //     url: topicURL,
                    //     method: "delete",
                    //     headers: {
                    //         "Authorization": `key=${process.env.REACT_APP_FCM_SERVER_KEY}`
                    //     },
                    // })
                    //     .then((response) => {
                    //         // See the MessagingTopicManagementResponse reference documentation
                    //         // for the contents of response.
                    //         console.log('Successfully unsubsribe from topic:', response);
                    //     })
                    //     .catch((error) => {
                    //         console.log('Error unsubsribe from topic:', error);
                    //     });
                })
                .catch(function (err) {
                    console.log("Didn't get notification permission", err);
                });

        } catch (error) {
            console.error(error)
        }
    },

    messageListener: function () {
        messaging.onMessage(function (payload) {
            navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
                registration.showNotification(
                    payload.notification.title,
                    payload.notification
                )
            });
            return payload;
        })

        // Event listener that listens for the push notification event in the background
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.addEventListener("message", (event) => {
                return event.data?.firebaseMessaging?.payload?.notification;
            });
        }
    },

    onMessage: async () => {
        messaging.onMessage(function (payload) {
            console.log("Message received. ", payload);
            alert("Notificacion");
        });
    },
}

export { firebaseCloudMessaging }